import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormManufacturer} from '@app/components/manufacturer/FormManufacturer/FormManufacturer';

const AddManufacturerPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('manufacturer.addPageHeader')}</PageTitle>
      <PageLayout title={t('manufacturer.addPageHeader')} backButtonLink={'/manufacturers/list'}>
        <FormManufacturer />
      </PageLayout>
    </>
  );
};

export default AddManufacturerPage;
