import {MachineModel} from '@app/domain/MachineModel';
import {httpApi} from './http.api';

export interface CreateMachineRequest {
  internalId: string;
  name: string;
  description: string;
  entityStatus: number;
  manufactureDate: Date;
  purchaseDate: Date;
  warrantyExpiryDate: Date;
  nextServiceDate: Date;
  manufacturerId: string;
  model: string;
  serialNumber: string;
}

export interface UpdateMachineRequest extends CreateMachineRequest {
  id: string;
}

export const getAllMachines = (): Promise<MachineModel[]> =>
  httpApi.get<MachineModel[]>(process.env.REACT_APP_MACHINE_GET_ALL_API!).then(({data}) => data);

export const getSingleMachine = (machineId: string): Promise<MachineModel> =>
  httpApi
    .get<MachineModel>(process.env.REACT_APP_MACHINE_GET_SINGLE_API!, {params: {machineId: machineId}})
    .then(({data}) => data);

export const createMachine = (createMachineRequest: CreateMachineRequest): Promise<boolean> =>
  httpApi.post<boolean>(process.env.REACT_APP_MACHINE_CREATE_API!, createMachineRequest).then(({data}) => data);

export const updateMachine = (updateMachineRequest: UpdateMachineRequest): Promise<boolean> =>
  httpApi.put<boolean>(process.env.REACT_APP_MACHINE_UPDATE_API!, updateMachineRequest).then(({data}) => data);

export const deleteMachine = (machineId: string): Promise<boolean> =>
  httpApi
    .delete<boolean>(process.env.REACT_APP_MACHINE_DELETE_API!, {params: {machineId: machineId}})
    .then(({data}) => data);

export const getAllInternalIds = (): Promise<string[]> =>
  httpApi.get<string[]>(process.env.REACT_APP_MACHINE_GET_ALL_INTERNAL_IDS_API!).then(({data}) => data);
