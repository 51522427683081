import {eUserRoles} from '@app/interfaces/enums';
import {decodeToken, readToken} from '@app/services/localStorage.service';

interface PermissionsReturnValues {
  hasUserCreatePermission: boolean;
}

export const usePermissions = (): PermissionsReturnValues => {
  const userRole = decodeToken(readToken()).userRole;

  const hasUserCreatePermission = (() => {
    return userRole === eUserRoles.Administrator;
  })();

  return {
    hasUserCreatePermission,
  };
};
