import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import {
  CreateManufacturerRequest,
  UpdateManufacturerRequest,
  createManufacturer,
  getAllManufacturers,
  getSingleManufacturer,
  updateManufacturer,
} from '@app/api/manufacturer.api';
import {CountriesItem} from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';

interface FormManufacturerProps {
  edit?: boolean;
}

export const FormManufacturer: React.FC<FormManufacturerProps> = ({edit}) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {manufacturerId} = useParams();

  const [manufacturers, setManufacturers] = useState<ManufacturerModel[]>([]);

  const [gettingEditData, setGettingEditData] = useState(false);

  const [currentEditManufacturerData, setCurrentEditManufacturerData] = useState<ManufacturerModel>();
  const [initialValues, setInitialValues] = useState<any>();

  useEffect(() => {
    if (edit) {
      if (manufacturerId === undefined) {
        navigate('/manufacturers/list');
        return;
      }

      setGettingEditData(true);

      getSingleManufacturer(manufacturerId).then((res) => {
        setInitialValues({
          name: res.name,
          description: res.description,
          country: res.country,
          industry: res.industry,
          website: res.website,
          authorizedServiceProvider: res.authorizedServiceProvider,
        });

        setCurrentEditManufacturerData(res);

        setGettingEditData(false);
      });
    }
  }, []);

  const {t} = useTranslation();
  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  const onFinish = async (values: UpdateManufacturerRequest) => {
    setLoading(true);

    if (edit && manufacturerId) {
      values['id'] = manufacturerId;

      updateManufacturer(values)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('manufacturer.notifications.updateSuccess')});
          navigate('/manufacturers/');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notificationController.error({message: t('manufacturer.notifications.updateError')});
        });

      setLoading(false);
      return;
    } else {
      createManufacturer(values as CreateManufacturerRequest)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('manufacturer.notifications.createSuccess')});
          navigate('/manufacturers/');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notificationController.error({message: t('manufacturer.notifications.createError')});
        });
    }
  };

  return (
    <>
      {gettingEditData ? (
        ''
      ) : (
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          initialValues={initialValues}
          name="validateForm"
          footer={
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </BaseButton>
            </BaseButtonsForm.Item>
          }
          onFinish={onFinish}
        >
          <BaseButtonsForm.Item
            name="name"
            label={t('manufacturer.name')}
            rules={[{required: true, message: t('manufacturer.form.validationLabels.name')}]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item
            name="description"
            label={t('manufacturer.description')}
            rules={[{required: true, message: t('manufacturer.form.validationLabels.description')}]}
          >
            <BaseInput.TextArea rows={4} />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item
            name="industry"
            label={t('manufacturer.industry')}
            rules={[{required: true, message: t('manufacturer.form.validationLabels.industry')}]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item
            name="website"
            label={t('manufacturer.website')}
            rules={[{required: true, message: t('manufacturer.form.validationLabels.website')}]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item
            name="authorizedServiceProvider"
            label={t('manufacturer.authorizedServiceProvider')}
            rules={[{required: true, message: t('manufacturer.form.validationLabels.authorizedServiceProvider')}]}
          >
            <BaseInput.TextArea rows={4} />
          </BaseButtonsForm.Item>

          {/* <BaseButtonsForm.Item name="country" rules={[{required: true, message: t('manufacturer.form.validationLabels.country')}]}>
            <CountriesItem />
        </BaseButtonsForm.Item> */}
        </BaseButtonsForm>
      )}
    </>
  );
};
