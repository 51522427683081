import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {MachineList} from '@app/components/machine/ListMachine';
import ListOptionsMachine from '@app/components/machine/OptionsMachine/ListOptionsMachine';

const ListMachinePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.machines')}</PageTitle>
      <PageLayout title={t('objects.machines')} pageOptions={<ListOptionsMachine />}>
        <MachineList />
      </PageLayout>
    </>
  );
};

export default ListMachinePage;
