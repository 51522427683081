import React from 'react';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import {withLoading} from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/DashboardPage';

// Machines
import ListMachinePage from '@app/pages/machine/ListMachinePage';
import ViewMachinePage from '@app/pages/machine/ViewMachinePage';
import AddMachinePage from '@app/pages/machine/AddMachinePage';
import AddRegularMaintenancePage from '@app/pages/maintenances/regular-maintenance/AddRegularMaintenancePage';
import AddDowntimeMaintenancePage from '@app/pages/maintenances/downtime-maintenance/AddDowntimeMaintenancePage';
import AddPlannedMaintenancePage from '@app/pages/maintenances/planned-maintenance/AddPlannedMaintenancePage';
import ListUserPage from '@app/pages/user/ListUserPage';
import ViewUserPage from '@app/pages/user/ViewUserPage';
import ListRegularMaintenancePage from '@app/pages/maintenances/regular-maintenance/ListRegularMaintenancePage';
import ViewRegularMaintenancePage from '@app/pages/maintenances/regular-maintenance/ViewRegularMaintenancePage';
import ListManufacturerPage from '@app/pages/manufacturers/ListManufacturerPage';
import AddManufacturerPage from '@app/pages/manufacturers/AddManufacturerPage';
import ViewManufacturerPage from '@app/pages/manufacturers/ViewManufacturerPage';
import EditMachinePage from '@app/pages/machine/EditMachinePage';
import EditRegularMaintenancePage from '@app/pages/maintenances/regular-maintenance/EditRegularMaintenancePage';
import EditManufacturerPage from '@app/pages/manufacturers/EditManufacturerPage';
import ListSparePartPage from '@app/pages/spare-part/ListSparePartPage';
import ListDowntimeMaintenancePage from '@app/pages/maintenances/downtime-maintenance/ListDowntimeMaintenancePage';
import ListPlannedMaintenancePage from '@app/pages/maintenances/planned-maintenance/ListPlannedMaintenancePage';
import ViewDowntimeMaintenancePage from '@app/pages/maintenances/downtime-maintenance/ViewDowntimeMaintenancePage';
import EditDowntimeMaintenancePage from '@app/pages/maintenances/downtime-maintenance/EditDowntimeMaintenancePage';
import ViewPlannedMaintenancePage from '@app/pages/maintenances/planned-maintenance/ViewPlannedMaintenancePage';
import ViewMachineServiceHistoryPage from '@app/pages/machineServiceHistory/ViewMachineServiceHistoryPage';
// import ListSparePartPage from '@app/pages/spare-part/ListSparePartPage';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/nft-dashboard';
export const MEDICAL_DASHBOARD_PATH = 'medical-dashboard';

const Dashboard = withLoading(DashboardPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

// Dodato

export const AppRouter: React.FC = () => {
  function Redirect({to}: {to: string}) {
    const navigate = useNavigate();
    React.useEffect(() => {
      navigate(to);
    }, [navigate, to]);

    return null;
  }

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={protectedLayout}>
          {/* Dashboard */}
          <Route index element={<Dashboard />} />

          {/* Machines */}
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            {/* <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} /> */}
          </Route>

          {/* Machines */}
          <Route path="/machines" element={<Redirect to="/machines/list" />} />
          <Route path="machines">
            <Route path="list" element={<ListMachinePage />} />
            <Route path="add" element={<AddMachinePage />} />
            <Route path="view/:machineId" element={<ViewMachinePage />} />
            <Route path="edit/:machineId" element={<EditMachinePage />} />
          </Route>

          {/* Machine Service History */}
          <Route path="machine-service-history">
            <Route path="view/:machineServiceHistoryId" element={<ViewMachineServiceHistoryPage />} />
          </Route>

          {/* Regular maintenance */}
          <Route path="/regular-maintenance" element={<Redirect to="/regular-maintenance/list" />} />
          <Route path="regular-maintenance">
            <Route path="list" element={<ListRegularMaintenancePage />} />
            <Route path="add" element={<AddRegularMaintenancePage />} />
            <Route path="view/:maintenanceId" element={<ViewRegularMaintenancePage />} />
            <Route path="edit/:maintenanceId" element={<EditRegularMaintenancePage />} />
          </Route>

          {/* Downtime*/}
          <Route path="/downtime-maintenance" element={<Redirect to="/downtime-maintenance/list" />} />
          <Route path="downtime-maintenance">
            <Route path="list" element={<ListDowntimeMaintenancePage />} />
            <Route path="add" element={<AddDowntimeMaintenancePage />} />
            <Route path="view/:maintenanceId" element={<ViewDowntimeMaintenancePage />} />
            {/* <Route path="edit/:maintenanceId" element={<EditDowntimeMaintenancePage />} /> */}
          </Route>

          {/* Planned maintenance */}
          <Route path="/planned-maintenance" element={<Redirect to="/planned-maintenance/list" />} />
          <Route path="planned-maintenance">
            <Route path="list" element={<ListPlannedMaintenancePage />} />
            <Route path="add" element={<AddPlannedMaintenancePage />} />
            <Route path="view/:maintenanceId" element={<ViewPlannedMaintenancePage />} />
            {/* <Route path="edit/:maintenanceId" element={<EditDowntimeMaintenancePage />} /> */}
          </Route>

          <Route path="/manufacturers" element={<Redirect to="/manufacturers/list" />} />
          <Route path="manufacturers">
            <Route path="list" element={<ListManufacturerPage />} />
            <Route path="add" element={<AddManufacturerPage />} />
            <Route path="view/:manufacturerId" element={<ViewManufacturerPage />} />
            <Route path="edit/:manufacturerId" element={<EditManufacturerPage />} />
          </Route>

          <Route path="/spare-parts" element={<Redirect to="/spare-parts/list" />} />
          <Route path="spare-parts">
            <Route path="list" element={<ListSparePartPage />} />
            <Route path="add" element={<AddManufacturerPage />} />
            <Route path="view/:sparePartId" element={<ViewManufacturerPage />} />
            <Route path="edit/:sparePartId" element={<EditManufacturerPage />} />
          </Route>

          {/* Users */}
          <Route path="/users" element={<Redirect to="/users/list" />} />
          <Route path="users">
            <Route path="list" element={<ListUserPage />} />
            <Route path="add" element={<p>Dodaj korisnika </p>} />
            <Route path="view/:userId" element={<ViewUserPage />} />
          </Route>
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>

        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
