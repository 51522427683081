import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {FormMachine} from '@app/components/machine/FormMachine/FormMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {useParams} from 'react-router-dom';

const AddMachinePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('machine.addPageHeader')}</PageTitle>
      <PageLayout title={t('machine.addPageHeader')} backButtonLink={'/machines/list'}>
        <FormMachine />
      </PageLayout>
    </>
  );
};

export default AddMachinePage;
