import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormPlannedMaintenance} from '@app/components/maintenance/FormMaintenance/FormPlannedMaintenance';

const AddPlannedMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('maintenance.planned.addPageHeader')}</PageTitle>
      <PageLayout title={t('maintenance.planned.addPageHeader')} backButtonLink={'/machines/list'}>
        <FormPlannedMaintenance />
      </PageLayout>
    </>
  );
};

export default AddPlannedMaintenancePage;
