import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {DeleteFilled, DownOutlined, EditFilled, MoreOutlined, PlusOutlined} from '@ant-design/icons';
import {BaseModal} from '@app/components/common/BaseModal/BaseModal';
import {deleteMachine} from '@app/api/machine.api';
import {notificationController} from '@app/controllers/notificationController';
import {useResponsive} from '@app/hooks/useResponsive';
import {BaseDropdown} from '@app/components/common/BaseDropdown/Dropdown';
import {MenuProps} from 'antd';
import {deleteMaintenance} from '@app/api/maintenance.api';

const ViewOptionsPlannedMaintenance: React.FC = () => {
  const navigate = useNavigate();
  const {maintenanceId} = useParams();
  const {t} = useTranslation();
  const [isBasicModalOpen, setIsBasicModalOpen] = useState<boolean>(false);

  const {isTablet} = useResponsive();

  const onDelete = () => {
    setIsBasicModalOpen(true);
  };

  const onEdit = () => {
    navigate('/planned-maintenance/edit/' + maintenanceId);
  };

  const onModalConfirm = () => {
    deleteMaintenance(maintenanceId!)
      .then((res) => {
        if (res) {
          notificationController.success({
            message: 'Zastoj obrisan',
          });
          navigate('/planned-maintenance/list');
        }
      })
      .catch((err) => {
        notificationController.error({
          message: 'Greska prilikom brisanja zastoja',
          description: err.message,
        });
      });
    setIsBasicModalOpen(false);
  };

  const onModalCancel = () => {
    setIsBasicModalOpen(false);
  };

  const desktopAndTabletLayout = (
    <>
      <BaseButton type="default" icon={<DeleteFilled />} size="small" onClick={onDelete}>
        {t('common.delete')}
      </BaseButton>
      {/* <BaseButton type="default" icon={<EditFilled />} size="small" onClick={onEdit}>
        {t("common.edit")}
      </BaseButton> */}
      <BaseModal
        title={t('maintenance.planned.deleteModalHeader')}
        open={isBasicModalOpen}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
      >
        <p>{t('maintenance.planned.deleteModalBody')}</p>
      </BaseModal>
    </>
  );

  const items: MenuProps['items'] = [
    // {
    //   label: 'Edit',
    //   key: '2',
    //   icon: <EditFilled />,
    // },
    {
      label: 'Add new',
      key: '3',
      icon: <PlusOutlined />,
    },
    {
      label: 'Delete',
      key: '1',
      icon: <DeleteFilled />,
      danger: true,
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    // alert('klimnuo');
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const mobileLayout = (
    <>
      <BaseDropdown
        menu={menuProps}
        placement="bottomRight"
        arrow={{pointAtCenter: true}}
        // trigger={['click']}
      >
        <BaseButton onClick={(e) => e.preventDefault()} type="text">
          <MoreOutlined style={{fontSize: '30px'}} />
        </BaseButton>
      </BaseDropdown>
    </>
  );

  return <>{isTablet ? desktopAndTabletLayout : mobileLayout}</>;
};

export default ViewOptionsPlannedMaintenance;
