import {MachineServiceHistoryModel} from '@app/domain/MachineServiceHistoryModel';
import {httpApi} from './http.api';
import {DoneTaskModel, MaintenanceModel} from '@app/domain/MaintenanceModel';
import {MachineModel} from '@app/domain/MachineModel';
import {convertToMaintenanceModel, GetMaintenanceResponse} from './maintenance.api';

export interface CreateMachineServiceHistoryRequest {
  maintenanceId: string;
  machineId: string;
  description: string;
  completionDate: Date;
  doneTasks?: string;
}

export interface GetMachineServiceHistoryResponse {
  id: string;
  description: string;
  completionDate: Date;
  machine: MachineModel;
  maintenance: GetMaintenanceResponse;
  doneTasks?: string;
}

function convertToMachineServiceHistoryModel(
  response: GetMachineServiceHistoryResponse,
  tasks: DoneTaskModel[],
): MachineServiceHistoryModel {
  const {id, description, completionDate, machine} = response;

  const maintenance = convertToMaintenanceModel(response.maintenance, JSON.parse(response.maintenance.tasks!));

  return {
    id,
    description,
    completionDate,
    machine,
    maintenance,
    doneTasks: tasks,
  };
}

export const getAllMachineServiceHistory = (): Promise<MachineServiceHistoryModel[]> =>
  httpApi
    .get<MachineServiceHistoryModel[]>(process.env.REACT_APP_MACHINE_SERVICE_HISTORY_GET_ALL_API!)
    .then(({data}) => data);

export const createMachineServiceHistory = (
  createMachineServiceHistoryRequest: CreateMachineServiceHistoryRequest,
): Promise<boolean> =>
  httpApi
    .post<boolean>(process.env.REACT_APP_MACHINE_SERVICE_HISTORY_CREATE_API!, createMachineServiceHistoryRequest)
    .then(({data}) => data);

export const getSingleMachineServiceHistory = (machineServiceHistoryId: string): Promise<MachineServiceHistoryModel> =>
  httpApi
    .get<GetMachineServiceHistoryResponse>(process.env.REACT_APP_MACHINE_SERVICE_HISTORY_GET_SINGLE_API!, {
      params: {machineServiceHistoryId: machineServiceHistoryId},
    })
    .then(({data}) => convertToMachineServiceHistoryModel(data, JSON.parse(data.doneTasks!)));
