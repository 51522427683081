import React, {useEffect, useState} from 'react';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {useResponsive} from '@app/hooks/useResponsive';
import * as S from './DashboardPageOriginal.styles';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';

import PendingActionsIcon from '@mui/icons-material/PendingActions';
import BuildIcon from '@mui/icons-material/Build';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import './dashboard.css';

import ListMachinePage from '../machine/ListMachinePage';
import {Avatar, Divider, List, Skeleton} from 'antd';
import {getAllMaintenances} from '@app/api/maintenance.api';
import {MaintenanceModel} from '@app/domain/MaintenanceModel';
import {eMaintenanceType} from '@app/interfaces/enums';
import {useNavigate} from 'react-router-dom';
import {formatDate} from '@app/utils/utils';
import {QuickPreviewMachines} from '@app/components/dashboard/QuickPreviewMachines';
import {BaseCarousel} from '@app/components/common/BaseCarousel/Carousel';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {filterUpcomingAndOverdue, getDaysUntilNextWorkOrder} from '@app/services/maintenances.service';

const MedicalDashboardPage: React.FC = () => {
  const {isDesktop} = useResponsive();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<MaintenanceModel[]>([]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    getAllMaintenances()
      .then((res) => {
        setData(filterUpcomingAndOverdue(res));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  const navigate = useNavigate();

  const renderIcon = (maintenanceType: eMaintenanceType) => {
    switch (maintenanceType) {
      case eMaintenanceType.Regular_Maintenance:
        return <BuildIcon style={{color: 'yellow'}} />;
      case eMaintenanceType.Downtime:
        return <ReportProblemIcon style={{color: 'red'}} />;
      case eMaintenanceType.Planned_Maintenance:
        return <PendingActionsIcon style={{color: 'green'}} />;
      default:
        return;
    }
  };

  // const daysUntil = (date: Date): number => {
  //   const now = new Date();
  //   const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  //   const diffDays = Math.round((date.getTime() - now.getTime()) / oneDay);
  //   return diffDays;
  // };

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <QuickPreviewMachines />
      </S.LeftSideCol>
      <S.RightSideCol xl={8} xxl={7} style={{background: 'transparent'}}>
        <div
          id="scrollableDiv"
          style={
            {
              // overflow: 'auto',
              // padding: '0 16px',
              // border: '1px solid rgba(140, 140, 140, 0.35)',
            }
          }
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                onClick={() => {
                  switch (item.maintenanceType) {
                    case eMaintenanceType.Regular_Maintenance:
                      navigate('/regular-maintenance/view/' + item.id);
                      break;
                    case eMaintenanceType.Downtime:
                      navigate('/downtimes/view/' + item.id);
                      break;
                    case eMaintenanceType.Planned_Maintenance:
                      navigate('/planned-maintenance/view/' + item.id);
                      break;
                  }
                }}
                style={{
                  backgroundColor: '#fff',
                  boxShadow: '0 0 13px 0 rgba(34, 62, 153, .1)',
                  borderRadius: '10px',
                  padding: '20px 25px',
                  cursor: 'pointer',
                  marginBottom: '10px',
                  position: 'relative',
                }}
              >
                {/* <List.Item.Meta
                  avatar={
                    item.maintenanceType == eMaintenanceType.Regular_Maintenance ? (
                      <BuildIcon style={{color: 'yellow'}} />
                    ) : item.maintenanceType == eMaintenanceType.Downtime ? (
                      <ReportProblemIcon style={{color: 'red'}} />
                    ) : item.maintenanceType == eMaintenanceType.Planned_Maintenance ? (
                      <PendingActionsIcon style={{color: 'green'}} />
                    ) : (
                      <></>
                    )
                  }
                  title={<span>{item.name}</span>}
                  description={item.name}
                /> */}
                <div style={{display: 'flex', alignItems: 'center', columnGap: '15px'}}>
                  {/* <BuildIcon style={{color: '#e7cf27'}} /> */}
                  {renderIcon(item.maintenanceType)}
                  <div style={{display: 'grid', marginBottom: '20px'}}>
                    <span>{item.name}</span>
                    <span style={{color: '#a3a0a0'}}>{item.machine.name}</span>
                  </div>
                  <div style={{position: 'absolute', bottom: '8px', right: '8px'}}>
                    {formatDate(item.nextWorkOrder)}
                  </div>
                  {/* <div style={{position: 'absolute', bottom: '8px', right: '8px'}}>{getDaysUntilNextWorkOrder(item)}</div> */}
                </div>
              </List.Item>
            )}
          />
        </div>
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <>
      {/* <>
              <BaseCol>
                <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                  <LeftOutlined />
                </S.ArrowBtn>
              </BaseCol>

              <BaseCol>
                <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                  <RightOutlined />
                </S.ArrowBtn>
              </BaseCol>
            </> */}

      {/* <div>
        <div
          id="scrollableDiv"
          style={{overflow: 'auto', padding: '0 16px', border: '1px solid rgba(140, 140, 140, 0.35)'}}
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigate('/machines/view/' + item.machineId);
                }}
              >
                <List.Item.Meta
                  avatar={
                    item.maintenanceType == eMaintenanceType.Regular_Maintenance ? (
                      <BuildIcon style={{color: 'yellow'}} />
                    ) : item.maintenanceType == eMaintenanceType.Downtime ? (
                      <ReportProblemIcon style={{color: 'red'}} />
                    ) : item.maintenanceType == eMaintenanceType.Planned_Maintenance ? (
                      <PendingActionsIcon style={{color: 'green'}} />
                    ) : (
                      <></>
                    )
                  }
                  title={<span>{item.name}</span>}
                  description={item.name}
                />
                <div>{formatDate(item.nextWorkOrder)}</div>
              </List.Item>
            )}
          />
        </div>
      </div> */}

      {data.length > 0 && (
        <BaseCarousel
          // beforeChange={() => setDragging(true)}
          // afterChange={() => setDragging(false)}
          slidesToShow={11}
          // ref={sliderRef}
          centerMode={false}
          infinite={false}
          responsive={[
            {
              breakpoint: 1920,
              settings: {
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 1870,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1530,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1370,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1279,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1020,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 920,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 820,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {/* {stories.map((story, index) => (
            <div key={index}>
              <S.CardWrapper>
                <TrendingCreatorsStory
                  img={story.header.profileImage}
                  viewed={story.viewed}
                  onStoryOpen={() => {
                    if (!dragging) {
                      setStoryIndex(index);
                      setStoryOpened(true);
                    }
                  }}
                />
              </S.CardWrapper>
            </div>
          ))} */}
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                switch (item.maintenanceType) {
                  case eMaintenanceType.Regular_Maintenance:
                    navigate('/regular-maintenance/view/' + item.id);
                    break;
                  case eMaintenanceType.Downtime:
                    navigate('/downtimes/view/' + item.id);
                    break;
                  case eMaintenanceType.Planned_Maintenance:
                    navigate('/planned-maintenance/view/' + item.id);
                    break;
                }
              }}
              style={{
                backgroundColor: '#fff',
                boxShadow: '0 0 13px 0 rgba(34, 62, 153, .1)',
                borderRadius: '10px',
                padding: '20px 25px',
                cursor: 'pointer',
                marginBottom: '10px',
                position: 'relative',
                marginRight: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '15px',
                  backgroundColor: '#fff',
                  boxShadow: '0 0 13px 0 rgba(34, 62, 153, .1)',
                  borderRadius: '10px',
                  padding: '20px 25px',
                  cursor: 'pointer',
                  marginBottom: '10px',
                  position: 'relative',
                  marginRight: '15px',
                }}
              >
                {renderIcon(item.maintenanceType)}
                {/* <BuildIcon style={{color: 'yellow'}} /> */}
                <div style={{display: 'grid'}}>
                  <span>{item.name}</span>
                  <span style={{color: '#a3a0a0'}}>{item.machine.name}</span>
                </div>
                <div style={{position: 'absolute', bottom: '8px', right: '8px'}}>{formatDate(item.nextWorkOrder)}</div>
                {/* <div style={{position: 'absolute', bottom: '8px', right: '8px'}}>{getDaysUntilNextWorkOrder(item)}</div> */}
              </div>
            </div>
          ))}
        </BaseCarousel>
      )}
      <BaseRow>
        <QuickPreviewMachines />
      </BaseRow>
    </>
  );

  return (
    <>
      <PageTitle>Home</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
