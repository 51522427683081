import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ViewMachine} from '@app/components/machine/ViewMachine/ViewMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ViewOptionsMachine from '@app/components/machine/OptionsMachine/ViewOptionsMachine';
import {ViewUser} from '@app/components/user/ViewUser/ViewUser';

const ViewUserPage: React.FC = () => {
  const [userFirstNameAndLastName, setUserFirstNameAndLastName] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>Korisnik</PageTitle>
      <PageLayout title={userFirstNameAndLastName} backButtonLink={'/users/list'}>
        <ViewUser setUserFirstNameAndLastName={setUserFirstNameAndLastName} />
      </PageLayout>
    </>
  );
};

export default ViewUserPage;
