import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ListPlannedMaintenance} from '@app/components/maintenance/planned-maintenance/ListPlannedMaintenance';

const ListPlannedMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.plannedServices')}</PageTitle>
      <PageLayout title={t('objects.plannedServices')}>
        <ListPlannedMaintenance />
      </PageLayout>
    </>
  );
};

export default ListPlannedMaintenancePage;
