import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ViewMachine} from '@app/components/machine/ViewMachine/ViewMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ViewOptionsMachine from '@app/components/machine/OptionsMachine/ViewOptionsMachine';
import {ViewMachineServiceHistory} from '@app/components/machine-service-history/ViewMachineServiceHistory/ViewMachineServiceHistory';

const ViewMachineServiceHistoryPage: React.FC = () => {
  const [machineServiceHistoryTitle, setMachineServiceHistoryTitle] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('common.machineServiceHistory')}</PageTitle>

      <PageLayout title={machineServiceHistoryTitle} backButtonLink={'/machines/list'}>
        <ViewMachineServiceHistory setMachineServiceHistoryTitle={setMachineServiceHistoryTitle} />
      </PageLayout>
    </>
  );
};

export default ViewMachineServiceHistoryPage;
