import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import * as SS from '@app/components/common/Section/Section.styles';
import {useNavigate, useParams} from 'react-router-dom';
import {useMounted} from '@app/hooks/useMounted';
import {useTranslation} from 'react-i18next';
import {getSingleUser} from '@app/api/user.api';
import {decodeToken, readToken} from '@app/services/localStorage.service';
import {usePermissions} from '@app/hooks/usePermissions';
import {UserModel} from '@app/domain/UserModel';

interface ViewUserProps {
  setUserFirstNameAndLastName: Dispatch<SetStateAction<string>>;
}

export const ViewUser: React.FC<ViewUserProps> = ({setUserFirstNameAndLastName}) => {
  const {userId} = useParams();
  const {isMounted} = useMounted();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const token = readToken();

  const [user, setUser] = useState<UserModel>(); // State to store the machine data

  const {hasUserCreatePermission} = usePermissions();

  const fetchUser = useCallback(
    async (userId: string) => {
      try {
        const data = await getSingleUser(userId);
        if (isMounted.current) {
          setUser(data);
          setUserFirstNameAndLastName(`${data.firstname} ${data.lastname}`);
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    },
    [userId, isMounted],
  );

  useEffect(() => {
    fetchUser(userId!);
  }, [fetchUser]);

  return (
    <>
      {user && (
        <>
          <SS.Section>
            <SS.SectionHeader>
              <SS.H2>{t('user.generalInfo')}</SS.H2>
              <SS.Hr />
            </SS.SectionHeader>
            <SS.SectionContent>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.username')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.username}</SS.SectionElementValue>
              </SS.SectionElement>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.firstname')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.firstname}</SS.SectionElementValue>
              </SS.SectionElement>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.lastname')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.lastname}</SS.SectionElementValue>
              </SS.SectionElement>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.accountType')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.accountType}</SS.SectionElementValue>
              </SS.SectionElement>
            </SS.SectionContent>
          </SS.Section>

          <SS.Section>
            <SS.SectionHeader>
              <SS.H2>{t('user.contactInfo')}</SS.H2>
              <SS.Hr />
            </SS.SectionHeader>
            <SS.SectionContent>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.phoneNumber')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.phoneNumber}</SS.SectionElementValue>
              </SS.SectionElement>
              <SS.SectionElement>
                <SS.SectionElementLabel>{t('user.email')}:</SS.SectionElementLabel>
                <SS.SectionElementValue>{user.email}</SS.SectionElementValue>
              </SS.SectionElement>
            </SS.SectionContent>
          </SS.Section>
        </>
      )}
    </>
  );
};
