import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMounted} from '@app/hooks/useMounted';
import {useNavigate, useParams} from 'react-router-dom';
import {useResponsive} from '@app/hooks/useResponsive';
import {MachineServiceHistoryModel} from '@app/domain/MachineServiceHistoryModel';
import {getSingleMachineServiceHistory} from '@app/api/machineServiceHistory.api';
import {formatDate} from '@app/utils/utils';
import * as SS from '@app/components/common/Section/Section.styles';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {DoneTaskModel, TaskModel} from '@app/domain/MaintenanceModel';

interface ViewMachineServiceHistoryProps {
  setMachineServiceHistoryTitle: Dispatch<SetStateAction<string>>;
}

export const ViewMachineServiceHistory: React.FC<ViewMachineServiceHistoryProps> = ({
  setMachineServiceHistoryTitle,
}) => {
  const {machineServiceHistoryId} = useParams();
  const {isMounted} = useMounted();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isTablet} = useResponsive();

  const [machineServiceHistory, setMachineServiceHistory] = useState<MachineServiceHistoryModel>(); // State to store the machine data

  const fetchMachineServiceHistory = useCallback(
    async (machineServiceHistoryId: string) => {
      try {
        const data = await getSingleMachineServiceHistory(machineServiceHistoryId);
        if (isMounted.current) {
          if (!data.doneTasks) {
            data.doneTasks = [];
          }

          if (!data.maintenance.tasks) {
            data.maintenance.tasks = [];
          }

          setMachineServiceHistory(data);
          setMachineServiceHistoryTitle(`${data!.maintenance.name} - ${formatDate(data.completionDate)}`);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [machineServiceHistoryId, isMounted],
  );

  const getFailedTasks = (doneTasks: DoneTaskModel[], allTasks: TaskModel[]): TaskModel[] => {
    const doneTaskNames = doneTasks.map((task) => task.taskName);

    const failedTasks = allTasks.filter((task) => !doneTaskNames.includes(task.taskName));

    return failedTasks;
  };

  useEffect(() => {
    fetchMachineServiceHistory(machineServiceHistoryId!);
  }, [fetchMachineServiceHistory]);

  return (
    <>
      {machineServiceHistory && (
        <>
          <BaseRow style={{justifyContent: 'space-between'}}>
            <SS.LeftSideCol md={24} xl={20} xxl={20} id="desktop-content">
              {/* Completed Maintenance Data */}
              <SS.Section>
                <SS.SectionHeader>
                  <SS.H2>{t('machineServiceHistory.completedMaintenance')}</SS.H2> <SS.Hr />
                </SS.SectionHeader>
                <SS.SectionContent style={{marginBottom: '30px'}}>
                  <SectionElement
                    translateLabel={'machineServiceHistory.completionDate'}
                    value={formatDate(machineServiceHistory.completionDate)}
                  />
                </SS.SectionContent>

                <div style={{marginBottom: '30px'}}>
                  <SectionElement
                    translateLabel={'machineServiceHistory.completedTasks'}
                    value={machineServiceHistory.doneTasks?.map((doneTasks) => (
                      <>
                        ✓ {doneTasks.taskName}
                        <br />
                      </>
                    ))}
                  />
                </div>

                <div style={{marginBottom: '30px'}}>
                  <SectionElement
                    translateLabel={'machineServiceHistory.incompletedTasks'}
                    value={getFailedTasks(
                      machineServiceHistory.doneTasks!,
                      machineServiceHistory.maintenance.tasks,
                    ).map((failedTasks) => (
                      <>
                        ✗ {failedTasks.taskName}
                        <br />
                      </>
                    ))}
                  />
                </div>

                <SectionElement
                  translateLabel={'machineServiceHistory.description'}
                  value={machineServiceHistory.description}
                />
              </SS.Section>

              {/* Maintenance Data */}
              <SS.Section>
                <SS.SectionHeader>
                  <SS.H2>{t('maintenance.regular.viewPageHeader')}</SS.H2> <SS.Hr />
                </SS.SectionHeader>
                <SS.SectionContent>
                  <SectionElement
                    translateLabel={'maintenance.regular.name'}
                    value={machineServiceHistory.maintenance.name}
                    link={'/regular-maintenance/view/' + machineServiceHistory.maintenance.id}
                  />

                  <SectionElement
                    translateLabel={'maintenance.lastWorkOrder'}
                    value={
                      machineServiceHistory.maintenance.lastWorkOrder != null
                        ? formatDate(machineServiceHistory.maintenance.lastWorkOrder)
                        : '-'
                    }
                  />

                  <SectionElement
                    translateLabel={'maintenance.nextWorkOrder'}
                    value={formatDate(machineServiceHistory.maintenance.nextWorkOrder)}
                  />
                </SS.SectionContent>
              </SS.Section>

              {/* Machine Data */}
              <SS.Section>
                <SS.SectionHeader>
                  <SS.H2>{t('maintenance.viewPage.machineData')}</SS.H2> <SS.Hr />
                </SS.SectionHeader>
                <SS.SectionContent>
                  <SectionElement
                    translateLabel={'machine.name'}
                    value={machineServiceHistory.machine.name}
                    link={'/machines/view/' + machineServiceHistory.machine.id}
                  />

                  <SectionElement translateLabel={'machine.name'} value={machineServiceHistory.machine.internalId} />

                  <SectionElement translateLabel={'machine.name'} value={machineServiceHistory.machine.model} />
                </SS.SectionContent>
              </SS.Section>
            </SS.LeftSideCol>
          </BaseRow>
        </>
      )}
    </>
  );
};

interface SectionElementProps {
  translateLabel: string;
  value: React.ReactNode;
  link?: string;
}

const SectionElement: React.FC<SectionElementProps> = ({translateLabel, value, link}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <SS.SectionElement>
      <SS.SectionElementLabel>{t(translateLabel)}:</SS.SectionElementLabel>
      {link ? (
        <SS.SectionElementValueLink onClick={() => navigate(link)}>
          <SS.SectionElementValue>{value}</SS.SectionElementValue>
        </SS.SectionElementValueLink>
      ) : (
        <SS.SectionElementValue>{value}</SS.SectionElementValue>
      )}
    </SS.SectionElement>
  );
};
