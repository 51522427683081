import React from 'react';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const ListOptionsMachine: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <BaseButton type="primary" icon={<PlusOutlined />} size="small" onClick={() => navigate('/machines/add/')}>
        {t('machine.addNewMachine')}
      </BaseButton>
    </>
  );
};

export default ListOptionsMachine;
