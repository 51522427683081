import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import {httpApi} from './http.api';

export interface CreateManufacturerRequest {
  name: string;
  description: string;
  country: string;
  industry: string;
  website: string;
}

export interface UpdateManufacturerRequest extends CreateManufacturerRequest {
  id: string;
}

export const getAllManufacturers = (): Promise<ManufacturerModel[]> =>
  httpApi.get<ManufacturerModel[]>(process.env.REACT_APP_MANUFACTURER_GET_ALL_API!).then(({data}) => data);

export const createManufacturer = (createManufacturerRequest: CreateManufacturerRequest): Promise<boolean> =>
  httpApi
    .post<boolean>(process.env.REACT_APP_MANUFACTURER_CREATE_API!, createManufacturerRequest)
    .then(({data}) => data);

export const getSingleManufacturer = (manufacturerId: string): Promise<ManufacturerModel> =>
  httpApi
    .get<ManufacturerModel>(process.env.REACT_APP_MANUFACTURER_GET_SINGLE_API!, {
      params: {manufacturerId: manufacturerId},
    })
    .then(({data}) => data);

export const updateManufacturer = (updateManufacturerRequest: UpdateManufacturerRequest): Promise<boolean> =>
  httpApi
    .put<boolean>(process.env.REACT_APP_MANUFACTURER_UPDATE_API!, updateManufacturerRequest)
    .then(({data}) => data);

export const deleteManufacturer = (manufacturerId: string): Promise<boolean> =>
  httpApi
    .delete<boolean>(process.env.REACT_APP_MANUFACTURER_DELETE_API!, {params: {manufacturerId: manufacturerId}})
    .then(({data}) => data);
