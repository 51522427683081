import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {getAllMachines} from '@app/api/machine.api';
import {useMounted} from '@app/hooks/useMounted';
import {MachineModel} from '@app/domain/MachineModel';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {formatDate, shortenText} from '@app/utils/utils';
import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import {Input} from 'antd';

const {Search} = Input;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface MachineListRow {
  id: number;
  key: number;
  name: string;
  age: number;
  address: string;
  tags?: Tag[];
}

export const MachineList: React.FC = () => {
  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const [allMachines, setAllMachines] = useState<MachineModel[]>([]);
  const [tableData, setTableData] = useState<{data: MachineModel[]; pagination: Pagination; loading: boolean}>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const threeColors = {'0%': '#d2222d', '30%': '#ffbe00', '80%': '#238724'};

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllMachines().then((res) => {
        if (isMounted.current) {
          setAllMachines(res);
          setTableData({data: res, pagination: initialPagination, loading: false});
        }
      });
      // getBasicTableData(pagination).then((res) => {
      //   if (isMounted.current) {
      //     setTableData({data: res.data, pagination: res.pagination, loading: false});
      //   }
      // });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<MachineListRow> = [
    {
      title: t('machine.serialNumber'),
      dataIndex: 'serialNumber',
    },
    {
      title: t('machine.name'),
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: MachineListRow) => record.name.includes(value.toString()),
    },
    {
      title: t('machine.model'),
      dataIndex: 'model',
    },
    {
      title: t('machine.manufacturer'),
      dataIndex: 'manufacturer',
      render: (value: ManufacturerModel) => <span>{value.name}</span>,
    },
    {
      title: t('machine.purchaseDate'),
      dataIndex: 'purchaseDate',
      render: (value: string) => <span>{formatDate(value)}</span>,
    },
    {
      title: t('machine.warrantyExpiryDate'),
      dataIndex: 'warrantyExpiryDate',
      render: (value: string) => <span>{value != null ? formatDate(value) : t('machine.noWarranty')}</span>,
    },
    // {
    //   title: 'Ocena masine',
    //   // dataIndex: 'nextServiceDate',
    //   render: (value: string) => {
    //     const random = Math.floor(Math.random() * 101);
    //     let color;

    //     if (random < 30) {
    //       color = '#d2222d';
    //     } else if (random < 80) {
    //       color = '#ffbe00';
    //     } else {
    //       color = '#238724';
    //     }

    //     return <Progress steps={10} percent={random} strokeColor={color} />;
    //   },
    // },
  ];

  const onSearchChange = (value: string) => {
    if (value == '') {
      setTableData({
        data: allMachines,
        pagination: initialPagination,
        loading: false,
      });
      return;
    }

    setTableData((tableData) => ({...tableData, loading: true}));

    const lowerCaseValue = value.toLowerCase();

    setTableData({
      data: allMachines.filter(
        (item) =>
          item.serialNumber?.toLowerCase().includes(lowerCaseValue) ||
          item.name?.toLowerCase().includes(lowerCaseValue) ||
          item.model?.toLowerCase().includes(lowerCaseValue) ||
          item.manufacturer.name?.toLowerCase().includes(lowerCaseValue) ||
          formatDate(item.purchaseDate)?.toLowerCase().includes(lowerCaseValue) ||
          formatDate(item.warrantyExpiryDate)?.toLowerCase().includes(lowerCaseValue),
      ),
      pagination: initialPagination,
      loading: false,
    });
  };

  return (
    <>
      <Search
        placeholder={t('maintenance.placeholders.searchMaintenance')}
        allowClear
        onChange={(event) => onSearchChange(event.target.value)}
        style={{marginBottom: '30px'}}
      />
      <BaseTable
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => {
              navigate('/machines/view/' + record.id);
            },
          };
        }}
        columns={columns}
        dataSource={tableData.data}
        // pagination={tableData.pagination}
        loading={tableData.loading}
        // onChange={handleTableChange}
        scroll={{x: 800}}
        // bordered
      />
    </>
  );
};
