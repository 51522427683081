import React, {useCallback, useEffect, useState} from 'react';
import {FileDoneOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space} from 'antd';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {CreateMachineServiceHistoryRequest, createMachineServiceHistory} from '@app/api/machineServiceHistory.api';
import {notificationController} from '@app/controllers/notificationController';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useResponsive} from '@app/hooks/useResponsive';
import {DoneTaskModel, TaskModel} from '@app/domain/MaintenanceModel';
import {BaseCheckbox} from '@app/components/common/BaseCheckbox/BaseCheckbox';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {getSingleMaintenance, updateMaintenance} from '@app/api/maintenance.api';
import dayjs from 'dayjs';
import {convertDateWithHours} from '@app/services/dates.services';

const {Option} = Select;

interface DrawerMachineServiceHistoryProps {
  machineId: string;
  maintenanceId: string;
  tasks?: TaskModel[];
}
// TODO: ON TREBA DA PRIMI TASKMODEL (TO DODAJ U PROPS) a kada vraca vraca DoneTaskModel koji ima boolean da li su cekirani ili ne
const DrawerMachineServiceHistory: React.FC<DrawerMachineServiceHistoryProps> = ({machineId, maintenanceId, tasks}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {isDesktop} = useResponsive();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCreate = async (values: CreateMachineServiceHistoryRequest) => {
    // setLoading(true);

    values.machineId = machineId;
    values.maintenanceId = maintenanceId;
    values.completionDate = convertDateWithHours(values.completionDate);

    values.doneTasks = tasks ? JSON.stringify(tasks) : '';

    createMachineServiceHistory(values)
      .then((res) => {
        console.log(res);
        // setLoading(false);
        notificationController.success({
          message: 'Success',
          description: 'Machine service history created successfully.',
        });
        navigate('/machines/view/' + machineId);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        notificationController.error({
          message: 'Error',
          description: 'Error occurred while creating machine service history. Please try again.',
        });
      });
  };

  return (
    <>
      <BaseButton type="primary" onClick={showDrawer} icon={<FileDoneOutlined />}>
        {t('maintenance.actions.maintenanceCompleted')}
      </BaseButton>
      <Drawer
        title={t('maintenance.actions.maintenanceCompleted')}
        width={isDesktop ? 720 : ''}
        onClose={onClose}
        open={open}
        // styles={{
        //   body: {
        //     paddingBottom: 80,
        //   },
        // }}
        extra={
          <Space>
            <BaseButton onClick={onClose}>{t('common.cancel')}</BaseButton>
            <BaseButton type="primary" form="addMachineServiceHistoryForm" key="submit" htmlType="submit">
              {t('common.confirm')}
            </BaseButton>
          </Space>
        }
      >
        <Form layout="vertical" id="addMachineServiceHistoryForm" onFinish={onCreate} hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="completionDate"
                label={t('maintenance.downtimeEndDateTime')}
                rules={[{required: true, message: t('common.selectDate')}]}
              >
                <BaseDatePicker showTime format={'DD.MM.YYYY HH'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label={t('machineServiceHistory.description')}
                rules={[
                  {
                    required: true,
                    message: t('common.requiredField'),
                  },
                ]}
              >
                <BaseInput.TextArea rows={4} placeholder={t('common.enterDescription')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <BaseButtonsForm.Item name="doneTasks" label={t('maintenance.tasks')}>
                <BaseCheckbox.Group>
                  {tasks &&
                    tasks.map((task, index) => (
                      <Row key={index}>
                        <Col>
                          <BaseCheckbox value={task.taskName}>{task.taskName}</BaseCheckbox>
                        </Col>
                      </Row>
                    ))}
                </BaseCheckbox.Group>
              </BaseButtonsForm.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerMachineServiceHistory;
