import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ViewDowntimeMaintenance} from '@app/components/maintenance/downtime-maintenance/ViewDowntimeMaintenance/ViewDowntimeMaintenance';
import ViewOptionsDowntimeMaintenance from '@app/components/maintenance/downtime-maintenance/OptionsDowntimeMaintenance/ViewOptionsDowntimeMaintenance';

const ViewDowntimeMaintenancePage: React.FC = () => {
  const [downtimeMaintenanceTitle, setDowntimeMaintenanceTitle] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{downtimeMaintenanceTitle}</PageTitle>
      <PageLayout
        title={downtimeMaintenanceTitle}
        pageOptions={<ViewOptionsDowntimeMaintenance />}
        backButtonLink="/downtime-maintenance/list"
      >
        <ViewDowntimeMaintenance setDowntimeMaintenanceTitle={setDowntimeMaintenanceTitle} />
      </PageLayout>
    </>
  );
};

export default ViewDowntimeMaintenancePage;
