import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {eMaintenancePriority, eMaintenanceType, eSchedules} from '@app/interfaces/enums';
import {Button, Form, Input, InputNumber, Select, SelectProps} from 'antd';
import {
  CreateMaintenanceRequest,
  UpdateMaintenanceRequest,
  createMaintenance,
  getSingleMaintenance,
  updateMaintenance,
} from '@app/api/maintenance.api';
import {MaintenanceModel, TaskModel} from '@app/domain/MaintenanceModel';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

interface FormRegularMaintenanceProps {
  edit?: boolean;
}

export const FormRegularMaintenance: React.FC<FormRegularMaintenanceProps> = ({edit}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {maintenanceId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [gettingEditData, setGettingEditData] = useState(false);

  const [currentEditMaintenanceData, setCurrentEditMaintenanceData] = useState<MaintenanceModel>();
  const [initialValues, setInitialValues] = useState<any>();

  const [tasks, setTasks] = useState<string[]>([]);

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  useEffect(() => {
    if (edit) {
      if (maintenanceId === undefined) {
        navigate('/regular-maintenance/list');
        return;
      }

      setGettingEditData(true);

      getSingleMaintenance(maintenanceId).then((res) => {
        const tasksArray = res.tasks ? res.tasks.map((task: TaskModel) => task.taskName) : [];

        setInitialValues({
          name: res.name,
          description: res.description,
          schedules: res.schedules,
          priority: res.priority,
          tasks: tasksArray,
        });

        setTasks(tasksArray);
        setCurrentEditMaintenanceData(res);

        setGettingEditData(false);
      });
    }
  }, []);

  const onFinish = async (values: UpdateMaintenanceRequest) => {
    const maintenanceObj: UpdateMaintenanceRequest = {
      id: values.id,
      name: values.name,
      description: values.description,
      priority: values.priority,
      maintenanceType: eMaintenanceType.Regular_Maintenance,
      schedules: values.schedules,
      tasks: JSON.stringify(values.tasks),
      machineId: searchParams.get('machineid')!,
      nextWorkOrder: new Date(),
    };

    setLoading(true);

    if (edit && maintenanceId && currentEditMaintenanceData) {
      maintenanceObj.id = maintenanceId;
      maintenanceObj.machineId = currentEditMaintenanceData.machine.id;

      updateMaintenance(maintenanceObj)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('maintenance.regular.form.notifications.updateSuccess')});
          navigate('/machines/view/' + res.machine.id);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          notificationController.error({message: t('maintenance.regular.form.notifications.updateError')});
        });

      setLoading(false);
    } else {
      createMaintenance(maintenanceObj)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('maintenance.regular.form.notifications.createSuccess')});
          navigate('/machines/view/' + res.machine.id);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          notificationController.error({message: t('maintenance.regular.form.notifications.createError')});
        });
    }
  };

  const onTaskInputChange = (event: any, index: number) => {
    const {value} = event.target;
    const updatedTasks = Array.isArray(tasks) ? [...tasks] : [];
    updatedTasks[index] = value;

    setTasks(updatedTasks);
  };

  return (
    <>
      {gettingEditData ? (
        ''
      ) : (
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          initialValues={initialValues}
          name="validateForm"
          footer={
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </BaseButton>
            </BaseButtonsForm.Item>
          }
          onFinish={onFinish}
        >
          <BaseRow gutter={{xs: 10, md: 15, xl: 30}} style={{maxWidth: '1000px'}}>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="name"
                label={t('maintenance.regular.form.name')}
                rules={[{required: true, message: t('maintenance.regular.form.validationLabels.name')}]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item name="priority" label={t('maintenance.regular.form.priority')}>
                <Select
                  allowClear
                  style={{width: '100%'}}
                  placeholder={t('maintenance.placeholders.selectPriority')}
                  options={[
                    {label: t('priority.low'), value: eMaintenancePriority.Low},
                    {label: t('priority.medium'), value: eMaintenancePriority.Medium},
                    {label: t('priority.high'), value: eMaintenancePriority.High},
                  ]}
                />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item name="schedules" label={t('maintenance.regular.form.schedules')}>
                <Select
                  allowClear
                  style={{width: '100%'}}
                  placeholder={t('maintenance.placeholders.selectSchedules')}
                  options={[
                    {label: t('maintenance.regular.form.schedulesSelectOptions.oneWeek'), value: eSchedules.One_Week},
                    {label: t('maintenance.regular.form.schedulesSelectOptions.twoWeek'), value: eSchedules.Two_Week},
                    {
                      label: t('maintenance.regular.form.schedulesSelectOptions.threeWeek'),
                      value: eSchedules.Three_Week,
                    },
                    {label: t('maintenance.regular.form.schedulesSelectOptions.oneMonth'), value: eSchedules.One_Month},
                    {
                      label: t('maintenance.regular.form.schedulesSelectOptions.threeMonth'),
                      value: eSchedules.Three_Month,
                    },
                    {label: t('maintenance.regular.form.schedulesSelectOptions.sixMonth'), value: eSchedules.Six_Month},
                    {label: t('maintenance.regular.form.schedulesSelectOptions.year'), value: eSchedules.Year},
                  ]}
                />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={18}>
              <Form.List name={'tasks'}>
                {(fields, {add, remove}) => (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Form.Item
                          key={field.key}
                          name={[field.name, 'taskName']}
                          label={index + 1 + '. Task'}
                          rules={[{required: true, message: t('manufacturer.form.validationLabels.website')}]}
                        >
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Input
                              value={tasks[index]}
                              onChange={(event) => onTaskInputChange(event, index)}
                              placeholder="Task"
                            />
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(index);
                              }}
                              style={{marginLeft: 8, fontSize: '25px', color: 'red'}}
                            />
                          </div>
                        </Form.Item>
                      );
                    })}

                    <BaseButtonsForm.Item>
                      <Button
                        icon={<PlusOutlined />}
                        type="dashed"
                        block
                        onClick={() => {
                          add();
                        }}
                      >
                        Add a Task
                      </Button>
                    </BaseButtonsForm.Item>
                  </>
                )}
              </Form.List>
            </BaseCol>

            <BaseCol xs={24} md={18}>
              <BaseButtonsForm.Item
                name="description"
                label={t('maintenance.regular.form.description')}
                rules={[{required: true, message: t('maintenance.regular.form.validationLabels.description')}]}
              >
                <BaseInput.TextArea rows={4} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      )}
    </>
  );
};
