import {MaintenanceModel, TaskModel} from '@app/domain/MaintenanceModel';
import {httpApi} from './http.api';
import {eMaintenancePriority, eMaintenanceType} from '@app/interfaces/enums';
import {MachineModel} from '@app/domain/MachineModel';
import {ta} from 'date-fns/locale';

export interface CreateMaintenanceRequest {
  name: string;
  description: string;
  maintenanceType: eMaintenanceType;
  machineId: string;
  schedules: number;
  priority: eMaintenancePriority | null;
  nextWorkOrder: Date;
}

export interface UpdateMaintenanceRequest extends CreateMaintenanceRequest {
  id: string;
  machineId: string;
  downtimeStartDateTime?: Date;
  downtimeEndDateTime?: Date;
  tasks: string;
}

export interface GetMaintenanceResponse {
  id: string;
  internalId: string;
  name: string;
  description: string;
  maintenanceType: eMaintenanceType;
  machine: MachineModel;
  schedules: number;
  priority: eMaintenancePriority;
  lastWorkOrder: Date;
  nextWorkOrder: Date;
  createdOn: Date;
  tasks: string;
  downtimeStartDateTime: Date;
  downtimeEndDateTime: Date;
}

export function convertToMaintenanceModel(response: GetMaintenanceResponse, tasks: TaskModel[]): MaintenanceModel {
  return {
    id: response.id,
    internalId: response.internalId,
    name: response.name,
    description: response.description,
    maintenanceType: response.maintenanceType,
    machine: response.machine,
    schedules: response.schedules,
    priority: response.priority,
    lastWorkOrder: response.lastWorkOrder,
    nextWorkOrder: response.nextWorkOrder,
    createdOn: response.createdOn,
    tasks: tasks,
    downtimeStartDateTime: response.downtimeStartDateTime,
    downtimeEndDateTime: response.downtimeStartDateTime,
  };
}

export const getAllMaintenances = (): Promise<MaintenanceModel[]> =>
  httpApi.get<MaintenanceModel[]>(process.env.REACT_APP_MAINTENANCE_GET_ALL_API!).then(({data}) => data);

export const getSingleMaintenance = (maintenanceId: string): Promise<MaintenanceModel> =>
  httpApi
    .get<GetMaintenanceResponse>(process.env.REACT_APP_MAINTENANCE_GET_SINGLE_API!, {
      params: {maintenanceId: maintenanceId},
    })
    .then(({data}) => convertToMaintenanceModel(data, JSON.parse(data.tasks)));

export const deleteMaintenance = (maintenanceId: string): Promise<boolean> =>
  httpApi
    .delete<boolean>(process.env.REACT_APP_MAINTENANCE_DELETE_API!, {params: {maintenanceId: maintenanceId}})
    .then(({data}) => data);

export const createMaintenance = (createMaintenanceRequest: CreateMaintenanceRequest): Promise<MaintenanceModel> =>
  httpApi
    .post<MaintenanceModel>(process.env.REACT_APP_MAINTENANCE_CREATE_API!, createMaintenanceRequest)
    .then(({data}) => data);

export const updateMaintenance = (updateMaintenanceRequest: UpdateMaintenanceRequest): Promise<MaintenanceModel> =>
  httpApi
    .put<MaintenanceModel>(process.env.REACT_APP_MAINTENANCE_UPDATE_API!, updateMaintenanceRequest)
    .then(({data}) => data);

/* ===== Regular maintenance ===== */

export const getAllRegularMaintenances = (): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_ALL_API!, {
      params: {maintenanceType: eMaintenanceType.Regular_Maintenance},
    })
    .then(({data}) => data);

export const getAllRegularMaintenancesByMachine = (machineId: string): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_BY_MACHINE_API!, {
      params: {maintenanceType: eMaintenanceType.Regular_Maintenance, machineId: machineId},
    })
    .then(({data}) => data);

/* ===== Downtime maintenance ===== */

export const getAllDowntimeMaintenances = (): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_ALL_API!, {
      params: {maintenanceType: eMaintenanceType.Downtime},
    })
    .then(({data}) => data);

export const getAllDowntimeMaintenancesByMachine = (machineId: string): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_BY_MACHINE_API!, {
      params: {maintenanceType: eMaintenanceType.Downtime, machineId: machineId},
    })
    .then(({data}) => data);

/* ===== Planned maintenance ===== */

export const getAllPlannedMaintenances = (): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_ALL_API!, {
      params: {maintenanceType: eMaintenanceType.Planned_Maintenance},
    })
    .then(({data}) => data);

export const getAllPlannedMaintenancesByMachine = (machineId: string): Promise<MaintenanceModel[]> =>
  httpApi
    .get<MaintenanceModel[]>(process.env.REACT_APP_REGULAR_MAINTENANCE_GET_BY_MACHINE_API!, {
      params: {maintenanceType: eMaintenanceType.Planned_Maintenance, machineId: machineId},
    })
    .then(({data}) => data);
