import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {useMounted} from '@app/hooks/useMounted';
import {MachineModel} from '@app/domain/MachineModel';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {Input, Space} from 'antd';
import {MaintenanceModel} from '@app/domain/MaintenanceModel';
import {BaseTable} from '@app/components/common/BaseTable/BaseTable';
import {getAllRegularMaintenances, getAllRegularMaintenancesByMachine} from '@app/api/maintenance.api';
import {eMaintenancePriority, eMaintenanceType} from '@app/interfaces/enums';
import {formatDate, renderSchedules} from '@app/utils/utils';
import {PriorityBars} from '@app/components/common/PriorityBars/PriorityBars';

const {Search} = Input;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface MaintenanceRow {
  id: string;
  name: string;
  nextWorkOrder: Date;
  schedules: number;
  priority: eMaintenancePriority;
  machine: MachineModel;
}

interface ListRegularMaintenanceForMachineProps {
  machineId: string;
}

export const ListRegularMaintenanceForMachine: React.FC<ListRegularMaintenanceForMachineProps> = ({machineId}) => {
  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const [allMaintenances, setAllMaintenances] = useState<MaintenanceModel[]>([]);
  const [tableData, setTableData] = useState<{data: MaintenanceModel[]; pagination: Pagination; loading: boolean}>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllRegularMaintenancesByMachine(machineId).then((res) => {
        if (isMounted.current) {
          // const helper = res.filter((x) => x.maintenanceType == eMaintenanceType.Regular_Maintenance);
          setAllMaintenances(res);
          setTableData({data: res, pagination: initialPagination, loading: false});
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<MaintenanceRow> = [
    {
      title: t('maintenance.regular.name'),
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: MaintenanceRow) => record.name.includes(value.toString()),
    },
    {
      title: t('maintenance.machine'),
      dataIndex: 'machine',
      render: (value: MachineModel) => <span>{value.name}</span>,
    },
    {
      title: t('maintenance.nextWorkOrder'),
      dataIndex: 'nextWorkOrder',
      render: (value: string) => <span>{formatDate(value)}</span>,
    },
    {
      title: t('maintenance.schedules'),
      dataIndex: 'schedules',
      render: (value: number) => <span>{renderSchedules(value)}</span>,
    },
    {
      title: t('maintenance.priority'),
      dataIndex: 'priority',
      render: (value: eMaintenancePriority) => (
        <span>
          <PriorityBars priority={value} />
        </span>
      ),
    },
  ];

  const onSearchChange = (value: string) => {
    console.log(value);

    if (value == '') {
      setTableData({
        data: allMaintenances,
        pagination: initialPagination,
        loading: false,
      });
      return;
    }

    setTableData((tableData) => ({...tableData, loading: true}));

    setTableData({
      // data: tableData.data.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())),
      data: allMaintenances.filter(
        (item) =>
          item.name?.toLowerCase().includes(value.toLowerCase()) ||
          item.description?.toLowerCase().includes(value.toLowerCase()) ||
          item.machine.name?.toLowerCase().includes(value.toLowerCase()),
      ),
      pagination: initialPagination,
      loading: false,
    });
  };

  return (
    <>
      <Search
        placeholder="Pretraga odrzavanja"
        allowClear
        onChange={(event) => onSearchChange(event.target.value)}
        style={{marginBottom: '30px'}}
      />
      <BaseTable
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log(record);
              navigate('/regular-maintenance/view/' + record.id);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        columns={columns}
        dataSource={tableData.data}
        // pagination={tableData.pagination}
        loading={tableData.loading}
        // onChange={handleTableChange}
        // scroll={{x: 800}}
        // bordered
        style={{width: '100%'}}
      />
    </>
  );
};
