import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ListUser} from '@app/components/user/ListUser';
import ListOptionsUser from '@app/components/user/OptionsUser/ListOptionsUser';

const ListUserPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.user')}</PageTitle>
      <PageLayout title={t('objects.user')} pageOptions={<ListOptionsUser />}>
        <ListUser />
      </PageLayout>
    </>
  );
};

export default ListUserPage;
