import {MaintenanceModel} from '@app/domain/MaintenanceModel';

export const filterUpcomingAndOverdue = (maintenances: MaintenanceModel[]): MaintenanceModel[] => {
  const today = new Date();

  return maintenances
    .filter((maintenance) => {
      const nextWorkOrderDate = new Date(maintenance.nextWorkOrder);
      return nextWorkOrderDate >= today || nextWorkOrderDate < today;
    })
    .sort((a, b) => {
      const daysUntilNextWorkOrderA = getDaysUntilNextWorkOrder(a);
      const daysUntilNextWorkOrderB = getDaysUntilNextWorkOrder(b);

      if (daysUntilNextWorkOrderA < 0 && daysUntilNextWorkOrderB < 0) {
        // If both dates are in the past, sort in ascending order
        return daysUntilNextWorkOrderA - daysUntilNextWorkOrderB;
      } else if (daysUntilNextWorkOrderA >= 0 && daysUntilNextWorkOrderB >= 0) {
        // If both dates are in the future, sort in ascending order
        return daysUntilNextWorkOrderA - daysUntilNextWorkOrderB;
      } else {
        // If one date is in the past and the other is in the future, put the past date first
        return daysUntilNextWorkOrderA < 0 ? -1 : 1;
      }
    });
};

export const getDaysUntilNextWorkOrder = (maintenance: MaintenanceModel): number => {
  const nextWorkOrderDate = new Date(maintenance.nextWorkOrder);
  const today = new Date();
  const timeDifference = nextWorkOrderDate.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference;
};
