import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {useMounted} from '@app/hooks/useMounted';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {Input} from 'antd';
import {UserModel} from '@app/domain/UserModel';
import {getAllUsers} from '@app/api/user.api';

const {Search} = Input;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface UserListRow {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  status: string;
  jobTitle: string;
  phoneNumber: string;
  companyName: string;
  accountType: string;
  lastVisit: Date;
}

export const ListUser: React.FC = () => {
  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState<UserModel[]>([]);
  const [tableData, setTableData] = useState<{data: UserModel[]; pagination: Pagination; loading: boolean}>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllUsers().then((res) => {
        if (isMounted.current) {
          setAllUsers(res);
          setTableData({data: res, pagination: initialPagination, loading: false});
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<UserListRow> = [
    {
      title: t('user.firstname'),
      dataIndex: 'firstname',
    },
    {
      title: t('user.lastname'),
      dataIndex: 'lastname',
    },
    {
      title: t('user.username'),
      dataIndex: 'username',
    },
    {
      title: t('user.jobTitle'),
      dataIndex: 'jobTitle',
    },
    {
      title: t('user.email'),
      dataIndex: 'email',
    },
    {
      title: t('user.phoneNumber'),
      dataIndex: 'phoneNumber',
    },
    {
      title: t('user.accountType'),
      dataIndex: 'accountType',
    },
  ];

  const onSearchChange = (value: string) => {
    if (value == '') {
      setTableData({
        data: allUsers,
        pagination: initialPagination,
        loading: false,
      });
      return;
    }

    setTableData((tableData) => ({...tableData, loading: true}));

    const lowerCaseValue = value.toLowerCase();

    setTableData({
      data: allUsers.filter(
        (item) =>
          item.firstname?.toLowerCase().includes(lowerCaseValue) ||
          item.lastname?.toLowerCase().includes(lowerCaseValue) ||
          item.username?.toLowerCase().includes(lowerCaseValue) ||
          item.jobTitle?.toLowerCase().includes(lowerCaseValue) ||
          item.email?.toLowerCase().includes(lowerCaseValue) ||
          item.phoneNumber?.toLowerCase().includes(lowerCaseValue) ||
          item.accountType?.toLowerCase().includes(lowerCaseValue),
      ),
      pagination: initialPagination,
      loading: false,
    });
  };

  return (
    <>
      <Search
        placeholder={t('maintenance.placeholders.searchMaintenance')}
        allowClear
        onChange={(event) => onSearchChange(event.target.value)}
        style={{marginBottom: '30px'}}
      />
      <BaseTable
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log(record);
              navigate('/users/view/' + record.id);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        columns={columns}
        dataSource={tableData.data}
        // pagination={tableData.pagination}
        loading={tableData.loading}
        // onChange={handleTableChange}
        scroll={{x: 800}}
        // bordered
      />
    </>
  );
};
