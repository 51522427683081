import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ViewMachine} from '@app/components/machine/ViewMachine/ViewMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ViewOptionsMachine from '@app/components/machine/OptionsMachine/ViewOptionsMachine';
import {ViewUser} from '@app/components/user/ViewUser/ViewUser';
import {ViewRegularMaintenance} from '@app/components/maintenance/regular-maintenance/ViewRegularMaintenance/ViewRegularMaintenance';
import ViewOptionsRegularMaintenance from '@app/components/maintenance/regular-maintenance/OptionsRegularMaintenance/ViewOptionsRegularMaintenance';

const ViewRegularMaintenancePage: React.FC = () => {
  const [regularMaintenanceTitle, setRegularMaintenanceTitle] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      {/* <PageTitle>{regularMaintenanceTitle}</PageTitle> */}
      <PageLayout
        title={regularMaintenanceTitle}
        pageOptions={<ViewOptionsRegularMaintenance />}
        backButtonLink="/regular-maintenance/list"
      >
        <ViewRegularMaintenance setRegularMaintenanceTitle={setRegularMaintenanceTitle} />
      </PageLayout>
    </>
  );
};

export default ViewRegularMaintenancePage;
