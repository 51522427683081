import React, {useEffect, useState} from 'react';
import * as S from './PriorityBars.styles';
import './priorityBars.css';
import {eMaintenancePriority} from '@app/interfaces/enums';
import {useTranslation} from 'react-i18next';

interface PriorityBarsProps {
  priority: eMaintenancePriority;
}

export const PriorityBars: React.FC<PriorityBarsProps> = ({priority}) => {
  const {t} = useTranslation();

  const renderPriorityBars = (priority: eMaintenancePriority) => {
    switch (priority) {
      case eMaintenancePriority.Low:
        return (
          <S.PriorityBarsContainer>
            <div className="signal-icon">
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
            </div>
            {t('priority.low')}
          </S.PriorityBarsContainer>
        );

      case eMaintenancePriority.Medium:
        return (
          <S.PriorityBarsContainer>
            <div className="signal-icon medium">
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
            </div>
            {t('priority.medium')}
          </S.PriorityBarsContainer>
        );

      case eMaintenancePriority.High:
        return (
          <S.PriorityBarsContainer>
            <div className="signal-icon strong">
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
              <div className="signal-bar"></div>
            </div>
            {t('priority.high')}
          </S.PriorityBarsContainer>
        );
    }
  };

  return <>{renderPriorityBars(priority)}</>;
};
