import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {useMounted} from '@app/hooks/useMounted';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {SparePartModel} from '@app/domain/SparePartModel';
import {getAllSpareParts} from '@app/api/sparePart.api';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface SparePartListRow {
  id: number;
  key: number;
  name: string;
}

export const SparePartList: React.FC = () => {
  const [tableData, setTableData] = useState<{data: SparePartModel[]; pagination: Pagination; loading: boolean}>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllSpareParts().then((res) => {
        if (isMounted.current) {
          setTableData({data: res, pagination: initialPagination, loading: false});
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<SparePartListRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: SparePartListRow) => record.name.includes(value.toString()),
    },
  ];
  return (
    <BaseTable
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            // console.log(record);
            navigate('/spare-parts/view/' + record.id);
          }, // click row
          // onDoubleClick: (event) => {}, // double click row
          // onContextMenu: (event) => {}, // right button click row
          // onMouseEnter: (event) => {}, // mouse enter row
          // onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
      columns={columns}
      dataSource={tableData.data}
      // pagination={tableData.pagination}
      loading={tableData.loading}
      // onChange={handleTableChange}
      scroll={{x: 800}}
      // bordered
    />
  );
};
