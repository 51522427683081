import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {UserModel} from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import {BaseAvatar} from '@app/components/common/BaseAvatar/BaseAvatar';

import defaultAvatarImage from '@app/assets/images/stub-avatar.webp';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({profileData}) => {
  const [fullness] = useState(90);

  const {t} = useTranslation();

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={profileData?.imgUrl} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.firstname} ${profileData?.lastname}`}</S.Title>
      <S.Subtitle>{profileData?.username}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
      {/* <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
