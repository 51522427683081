import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ViewOptionsManufacturer from '@app/components/manufacturer/OptionsManufacturer/ViewOptionsManufacturer';
import {ViewManufacturer} from '@app/components/manufacturer/ViewManufacturer/ViewManufacturer';

const ViewManufacturerPage: React.FC = () => {
  const [manufacturerName, setManufacturerName] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('common.manufacturers')}</PageTitle>

      <PageLayout
        title={manufacturerName}
        pageOptions={<ViewOptionsManufacturer />}
        backButtonLink={'/manufacturers/list'}
      >
        <ViewManufacturer setManufacturerTitle={setManufacturerName} />
      </PageLayout>
    </>
  );
};

export default ViewManufacturerPage;
