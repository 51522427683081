import React from 'react';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {usePermissions} from '@app/hooks/usePermissions';

const ListOptionsUser: React.FC = () => {
  const navigate = useNavigate();
  const {hasUserCreatePermission} = usePermissions();

  return (
    <>
      {hasUserCreatePermission && (
        <BaseButton type="primary" icon={<PlusOutlined />} size="small" onClick={() => navigate('/machines/add/')}>
          Add new user
        </BaseButton>
      )}
    </>
  );
};

export default ListOptionsUser;
