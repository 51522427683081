import React from 'react';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const ListOptionsSparePart: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <BaseButton type="primary" icon={<PlusOutlined />} size="small" onClick={() => navigate('/spare-part/add/')}>
        Add new spare part
      </BaseButton>
    </>
  );
};

export default ListOptionsSparePart;
