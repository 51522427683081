export const convertDateFromUtcToLocalDate = (date: Date): Date => {
  let dateString = date.toString();
  if (!dateString.includes('Z')) dateString += 'Z';
  const utcDate = new Date(dateString);
  // Detect local timezone of user
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Convert to local timezone of user
  const localDate = new Date(utcDate.toLocaleString('en-US', {timeZone: userTimeZone}));

  return localDate;
};

export const convertDateWithHours = (date: Date): Date => {
  const dateForConversion = new Date(date);
  dateForConversion.setSeconds(0);
  dateForConversion.setSeconds(0);
  dateForConversion.setMilliseconds(0);

  return dateForConversion;
};

export const toStringWithHours = (date: string): string => {
  let newDate = new Date(date);
  newDate = convertDateFromUtcToLocalDate(newDate);
  const day = String(newDate.getDate()).padStart(2, '0');
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const year = newDate.getFullYear();
  const hours = String(newDate.getHours()).padStart(2, '0');

  return `${day}.${month}.${year}. ${hours}:00`;
};
