import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ViewPlannedMaintenance} from '@app/components/maintenance/planned-maintenance/ViewPlannedMaintenance/ViewPlannedMaintenance';
import ViewOptionsPlannedMaintenance from '@app/components/maintenance/planned-maintenance/OptionsPlannedMaintenance/ViewOptionsPlannedMaintenance';

const ViewPlannedMaintenancePage: React.FC = () => {
  const [plannedMaintenanceTitle, setPlannedMaintenanceTitle] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{plannedMaintenanceTitle}</PageTitle>
      <PageLayout
        title={plannedMaintenanceTitle}
        pageOptions={<ViewOptionsPlannedMaintenance />}
        backButtonLink="/planned-maintenance/list"
      >
        <ViewPlannedMaintenance setPlannedMaintenanceTitle={setPlannedMaintenanceTitle} />
      </PageLayout>
    </>
  );
};

export default ViewPlannedMaintenancePage;
