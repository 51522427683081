import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {createMachine} from '@app/api/machine.api';
import {eMaintenanceType} from '@app/interfaces/enums';
import {CountriesItem} from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';
import {Select, SelectProps} from 'antd';
import {getAllUsers} from '@app/api/user.api';
import {UserModel} from '@app/domain/UserModel';
import {
  CreateMaintenanceRequest,
  UpdateMaintenanceRequest,
  createMaintenance,
  getSingleMaintenance,
  updateMaintenance,
} from '@app/api/maintenance.api';
import {MaintenanceModel} from '@app/domain/MaintenanceModel';
import {convertDateWithHours} from '@app/services/dates.services';

interface FormDowntimeMaintenanceProps {
  edit?: boolean;
}

export const FormDowntimeMaintenance: React.FC<FormDowntimeMaintenanceProps> = ({edit}) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {maintenanceId} = useParams();
  const [currentEditMaintenanceData, setCurrentEditMaintenanceData] = useState<MaintenanceModel>();

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [gettingEditData, setGettingEditData] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();

  const [userSelectOptions, setUserSelectOptions] = useState<SelectProps['options']>([]);

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
  };

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  useEffect(() => {
    if (edit) {
      if (maintenanceId === undefined) {
        navigate('/downtime-maintenance/list');
        return;
      }

      setGettingEditData(true);

      getSingleMaintenance(maintenanceId).then((res) => {
        setInitialValues({
          name: res.name,
          description: res.description,
        });

        setCurrentEditMaintenanceData(res);

        setGettingEditData(false);
      });
    }
  });

  const onFinish = async (values: UpdateMaintenanceRequest) => {
    setLoading(true);

    values.maintenanceType = eMaintenanceType.Downtime;
    values.machineId = searchParams.get('machineid')!;
    if (!!values.downtimeStartDateTime)
      values.downtimeStartDateTime = convertDateWithHours(values.downtimeStartDateTime);

    if (edit && maintenanceId && currentEditMaintenanceData) {
      values.id = maintenanceId;
      values.machineId = currentEditMaintenanceData.machine.id;

      updateMaintenance(values)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('maintenance.downtime.form.notifications.updateSuccess')});
          navigate('/machines/view/' + values.machineId);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          notificationController.error({message: t('maintenance.downtime.form.notifications.updateError')});
        });

      setLoading(false);
    } else {
      createMaintenance(values as CreateMaintenanceRequest)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('maintenance.downtime.form.notifications.createSuccess')});
          navigate('/machines/view/' + values.machineId);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          notificationController.error({message: t('maintenance.downtime.form.notifications.createError')});
        });
    }
  };

  const loadUsers = () => {
    if (isLoading) {
      return;
    }

    getAllUsers()
      .then((res) => {
        const options: SelectProps['options'] = [];
        res.forEach((x) => {
          options.push({
            label: x.firstname + ' ' + x.lastname,
            value: x.username,
          });
        });
        //}
        console.log(options);

        setUserSelectOptions(options);
        setUsers(res);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return (
    <>
      <BaseButtonsForm
        {...formItemLayout}
        isFieldsChanged={isFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        name="validateForm"
        footer={
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
      >
        <BaseButtonsForm.Item
          name="name"
          label={t('maintenance.downtime.form.name')}
          rules={[{required: true, message: t('maintenance.downtime.form.validationLabels.name')}]}
        >
          <BaseInput />
        </BaseButtonsForm.Item>

        <BaseButtonsForm.Item
          name="downtimeStartDateTime"
          label={t('maintenance.downtimeStartDateTime')}
          rules={[{required: true, message: t('maintenance.downtime.form.validationLabels.downtimeStartDateTime')}]}
        >
          <BaseDatePicker showTime format={'DD.MM.YYYY HH'} />
        </BaseButtonsForm.Item>

        <BaseButtonsForm.Item
          name="description"
          label={t('maintenance.downtime.form.description')}
          rules={[{required: true, message: t('maintenance.downtime.form.validationLabels.description')}]}
        >
          <BaseInput.TextArea rows={4} />
        </BaseButtonsForm.Item>

        {/* <BaseButtonsForm.Item
          name="assignTo"
          label={t('forms.addRegularMaintenanceForm.assignTo')}
          rules={[{required: true, message: t('forms.controlFormLabels.groupNameError')}]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            placeholder="Please select"
            onChange={handleChange}
            options={userSelectOptions}
          />
        </BaseButtonsForm.Item> */}
      </BaseButtonsForm>
    </>
  );
};
