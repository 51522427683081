import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormManufacturer} from '@app/components/manufacturer/FormManufacturer/FormManufacturer';
import {useParams} from 'react-router-dom';

const EditManufacturerPage: React.FC = () => {
  const {t} = useTranslation();
  const {manufacturerId} = useParams();

  return (
    <>
      <PageTitle>{t('manufacturer.editManufacturerTitle')}</PageTitle>
      <PageLayout
        title={t('manufacturer.editManufacturerTitle')}
        backButtonLink={'/manufacturers/view/' + manufacturerId}
      >
        <FormManufacturer edit={true} />
      </PageLayout>
    </>
  );
};

export default EditManufacturerPage;
