import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ListOptionsManufacturer from '@app/components/manufacturer/OptionsManufacturer/ListOptionsManufacturer';
import {ManufacturerList} from '@app/components/manufacturer/ListManufacturer';
import ListOptionsSparePart from '@app/components/spare-part/OptionsSparePart/ListOptionsSparePart';
import {SparePartList} from '@app/components/spare-part/ListSparePart';

const ListSparePartPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('common.spareParts')}</PageTitle>
      <PageLayout title={t('common.spareParts')} pageOptions={<ListOptionsSparePart />}>
        <SparePartList />
      </PageLayout>
    </>
  );
};

export default ListSparePartPage;
