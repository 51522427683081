import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ListOptionsManufacturer from '@app/components/manufacturer/OptionsManufacturer/ListOptionsManufacturer';
import {ManufacturerList} from '@app/components/manufacturer/ListManufacturer';

const ListManufacturerPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.machinesManufacturers')}</PageTitle>
      <PageLayout title={t('objects.machinesManufacturers')} pageOptions={<ListOptionsManufacturer />}>
        <ManufacturerList />
      </PageLayout>
    </>
  );
};

export default ListManufacturerPage;
