import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ListDowntimeMaintenance} from '@app/components/maintenance/downtime-maintenance/ListDowntimeMaintenance';

const ListDowntimeMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.downtime')}</PageTitle>
      <PageLayout title={t('objects.downtime')}>
        <ListDowntimeMaintenance />
      </PageLayout>
    </>
  );
};

export default ListDowntimeMaintenancePage;
