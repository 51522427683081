import React from 'react';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const ListOptionsManufacturer: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <>
      <BaseButton type="primary" icon={<PlusOutlined />} size="small" onClick={() => navigate('/manufacturers/add/')}>
        {t('manufacturer.addNew')}
      </BaseButton>
    </>
  );
};

export default ListOptionsManufacturer;
