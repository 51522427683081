import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ListUser} from '@app/components/user/ListUser';
import ListOptionsUser from '@app/components/user/OptionsUser/ListOptionsUser';
import {ListRegularMaintenance} from '@app/components/maintenance/regular-maintenance/ListRegularMaintenance';

const ListRegularMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('objects.maintenances')}</PageTitle>
      <PageLayout title={t('objects.maintenances')}>
        <ListRegularMaintenance />
      </PageLayout>
    </>
  );
};

export default ListRegularMaintenancePage;
