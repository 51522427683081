import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormRegularMaintenance} from '@app/components/maintenance/regular-maintenance/FormRegularMaintenance/FormRegularMaintenance';
import {useParams} from 'react-router-dom';

const EditRegularMaintenancePage: React.FC = () => {
  const {t} = useTranslation();
  const {maintenanceId} = useParams();

  return (
    <>
      <PageTitle>{t('maintenance.regular.editPageTitle')}</PageTitle>
      <PageLayout
        title={t('maintenance.regular.editPageTitle')}
        backButtonLink={'/regular-maintenance/view/' + maintenanceId}
      >
        <FormRegularMaintenance edit={true} />
      </PageLayout>
    </>
  );
};

export default EditRegularMaintenancePage;
