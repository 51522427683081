import styled from 'styled-components';

export const Table = styled.table`
  border: 1px solid #bfc0c2;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  // border: 1px solid #bfc0c2;
`;

export const TableData = styled.td`
  border: 1px solid #bfc0c2;
  padding: 5px;
`;
