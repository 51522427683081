import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ViewMachine} from '@app/components/machine/ViewMachine/ViewMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import ViewOptionsMachine from '@app/components/machine/OptionsMachine/ViewOptionsMachine';

const ViewMachinePage: React.FC = () => {
  const [machineName, setMachineName] = React.useState<string>('');
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('common.machines')}</PageTitle>

      <PageLayout title={machineName} pageOptions={<ViewOptionsMachine />} backButtonLink={'/machines/list'}>
        <ViewMachine setMachineName={setMachineName} />
      </PageLayout>
    </>
  );
};

export default ViewMachinePage;
