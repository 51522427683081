import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as SS from '@app/components/common/Section/Section.styles';
import {useMounted} from '@app/hooks/useMounted';
import {useNavigate, useParams} from 'react-router-dom';
import {useResponsive} from '@app/hooks/useResponsive';
import {MaintenanceModel} from '@app/domain/MaintenanceModel';
import {getSingleMaintenance} from '@app/api/maintenance.api';
import {eMaintenanceType} from '@app/interfaces/enums';
import {formatDate, formatYear, renderSchedules} from '@app/utils/utils';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import DrawerMachineServiceHistory from '@app/components/machine-service-history/DrawerMachineServiceHistory/DrawerMachineServiceHistory';
import {PlusOutlined} from '@ant-design/icons';

interface ViewPlannedMaintenanceProps {
  setPlannedMaintenanceTitle: Dispatch<SetStateAction<string>>;
}

export const ViewPlannedMaintenance: React.FC<ViewPlannedMaintenanceProps> = ({setPlannedMaintenanceTitle}) => {
  const {maintenanceId} = useParams();
  const {isMounted} = useMounted();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isTablet} = useResponsive();

  const [maintenance, setMaintenance] = useState<MaintenanceModel>(); // State to store the machine data

  const fetchMaintenance = useCallback(
    async (maintenanceId: string) => {
      try {
        const data = await getSingleMaintenance(maintenanceId);
        if (isMounted.current) {
          setMaintenance(data);
          setPlannedMaintenanceTitle(data!.internalId);
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    },
    [maintenanceId, isMounted],
  );

  useEffect(() => {
    fetchMaintenance(maintenanceId!);
  }, [fetchMaintenance]);

  return (
    <>
      {maintenance && (
        <>
          <BaseRow style={{justifyContent: 'space-between'}}>
            <SS.LeftSideCol md={16} xl={16} xxl={17} id="desktop-content">
              <SS.Section>
                <SS.SectionHeader>
                  <SS.H2>{t('maintenance.planned.viewPageHeader')}</SS.H2> <SS.Hr />
                </SS.SectionHeader>
                <SS.SectionContent>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('maintenance.planned.name')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{maintenance.name}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('maintenance.maintenanceType')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{eMaintenanceType[maintenance.maintenanceType]}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('maintenance.createdOn')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{formatDate(maintenance.createdOn)}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('maintenance.nextWorkOrder')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{formatDate(maintenance.nextWorkOrder)}</SS.SectionElementValue>
                  </SS.SectionElement>
                </SS.SectionContent>
                <SS.SectionElement style={{marginTop: '30px'}}>
                  <SS.SectionElementLabel>{t('maintenance.description')}:</SS.SectionElementLabel>
                  <SS.SectionElementValue>{maintenance.description}</SS.SectionElementValue>
                </SS.SectionElement>
              </SS.Section>

              <SS.Section>
                <SS.SectionHeader>
                  <SS.H2>{t('maintenance.viewPage.machineData')}</SS.H2> <SS.Hr />
                </SS.SectionHeader>
                <SS.SectionContent>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.name')}:</SS.SectionElementLabel>
                    <SS.SectionElementValueLink onClick={() => navigate('/machines/view/' + maintenance.machine.id)}>
                      {maintenance.machine.name}
                    </SS.SectionElementValueLink>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.serialNumber')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{maintenance.machine.serialNumber}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.model')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{maintenance.machine.model}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.manufacturer')}:</SS.SectionElementLabel>
                    <SS.SectionElementValueLink onClick={() => navigate('/manufacturers/view/1')}>
                      ABC Technologies
                    </SS.SectionElementValueLink>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.manufactureDate')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{formatYear(maintenance.machine.manufactureDate)}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.purchaseDate')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>{formatDate(maintenance.machine.purchaseDate)}</SS.SectionElementValue>
                  </SS.SectionElement>
                  <SS.SectionElement>
                    <SS.SectionElementLabel>{t('machine.warrantyExpiryDate')}:</SS.SectionElementLabel>
                    <SS.SectionElementValue>
                      {formatDate(maintenance.machine.warrantyExpiryDate)}
                    </SS.SectionElementValue>
                  </SS.SectionElement>
                </SS.SectionContent>
              </SS.Section>
            </SS.LeftSideCol>

            <SS.RightSideCol md={7} xl={6} xxl={5}>
              <DrawerMachineServiceHistory maintenanceId={maintenanceId!} machineId={maintenance.machine.id} />
            </SS.RightSideCol>
          </BaseRow>
        </>
      )}
    </>
  );
};
