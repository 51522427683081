import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormDowntimeMaintenance} from '@app/components/maintenance/FormMaintenance/FormDowntimeMaintenance';

const AddDowntimeMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('maintenance.downtime.addPageHeader')}</PageTitle>
      <PageLayout title={t('maintenance.downtime.addPageHeader')} backButtonLink={'/machines/list'}>
        <FormDowntimeMaintenance />
      </PageLayout>
    </>
  );
};

export default AddDowntimeMaintenancePage;
