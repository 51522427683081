import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BasicTable} from '../tables/BasicTable/BasicTable';
import * as S from './QuickPreviewMachines.styles';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {getAllMachines} from '@app/api/machine.api';
import {useMounted} from '@app/hooks/useMounted';
import {MachineModel} from '@app/domain/MachineModel';
import {ColumnsType} from 'antd/lib/table';
import {BaseRow} from '../common/BaseRow/BaseRow';
import {BaseCol} from '../common/BaseCol/BaseCol';
import {BaseSpace} from '../common/BaseSpace/BaseSpace';
import {BaseButton} from '../common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {useNavigate} from 'react-router-dom';
import {formatDate, shortenText} from '@app/utils/utils';
import {Table} from 'antd';
import type {TableProps} from 'antd';
import {Input, Space} from 'antd';
import type {SearchProps} from 'antd/es/input/Search';
import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import {use} from 'echarts';
import Item from 'antd/lib/list/Item';

const {Search} = Input;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface QuickPreviewMachinesRow {
  id: string;
  internalId: string;
  name: string;
  model: string;
  serialNumber: string;
  manufacturer: ManufacturerModel;
}

export const QuickPreviewMachines: React.FC = () => {
  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const [allMachines, setAllMachines] = useState<MachineModel[]>([]);
  const [tableData, setTableData] = useState<{data: MachineModel[]; pagination: Pagination; loading: boolean}>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [manufacturerFilters, setManufacturerFilters] = useState<{text: string; value: string}[]>([]);

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllMachines().then((res) => {
        if (isMounted.current) {
          setAllMachines(res);
          setTableData({data: res, pagination: initialPagination, loading: false});

          // Set manufacturer filters with all unique manufacturers
          const uniqueManufacturers = Array.from(new Set(res.map((machine) => machine.manufacturer.id)));
          const filters = uniqueManufacturers.map((id) => {
            const manufacturer = res.find((machine) => machine.manufacturer.id === id)?.manufacturer;
            return {value: id, text: manufacturer?.name || ''};
          });
          setManufacturerFilters(filters);
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<QuickPreviewMachinesRow> = [
    {
      title: t('machine.internalId'),
      dataIndex: 'internalId',
      sorter: (a, b) => a.internalId.localeCompare(b.internalId),
      defaultSortOrder: 'descend',
    },
    {
      title: t('machine.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('machine.model'),
      dataIndex: 'model',
    },
  ];

  const onSearchChange = (value: string) => {
    if (value == '') {
      setTableData({
        data: allMachines,
        pagination: initialPagination,
        loading: false,
      });
      return;
    }

    setTableData((tableData) => ({...tableData, loading: true}));

    setTableData({
      // data: tableData.data.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())),
      data: allMachines.filter(
        (item) =>
          item.name?.toLowerCase().includes(value.toLowerCase()) ||
          item.internalId?.toLowerCase().includes(value.toLowerCase()) ||
          item.model?.toLowerCase().includes(value.toLowerCase()),
      ),
      pagination: initialPagination,
      loading: false,
    });
  };

  return (
    <>
      <Search
        placeholder={t('machine.quickPreviewSearchPlaceholder')}
        allowClear
        onChange={(event) => onSearchChange(event.target.value)}
        style={{marginBottom: '30px'}}
      />
      <BaseTable
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log(record);
              navigate('/machines/view/' + record.id);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        columns={columns}
        dataSource={tableData.data}
        // pagination={tableData.pagination}
        loading={tableData.loading}
        // onChange={handleTableChange}
        // scroll={{x: 800}}
        // bordered
        style={{width: '100%'}}
      />
    </>
  );
};
