import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {useMounted} from '@app/hooks/useMounted';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {formatDate, shortenText} from '@app/utils/utils';
import {MachineServiceHistoryModel} from '@app/domain/MachineServiceHistoryModel';
import {getAllMachineServiceHistory} from '@app/api/machineServiceHistory.api';
import {MaintenanceModel} from '@app/domain/MaintenanceModel';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export interface MachineServiceHistoryListRow {
  id: number;
  key: number;
  maintenance: MaintenanceModel;
  description: string;
  completionDate: Date;
}

interface MachineServiceHistoryListProps {
  machineId?: string;
}

export const MachineServiceHistoryList: React.FC<MachineServiceHistoryListProps> = ({machineId}) => {
  const [tableData, setTableData] = useState<{
    data: MachineServiceHistoryModel[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({...tableData, loading: true}));
      getAllMachineServiceHistory().then((res) => {
        if (isMounted.current) {
          let filteredData = res;
          if (machineId) {
            filteredData = res.filter((item) => item.machine.id === machineId);
          }

          setTableData({data: filteredData, pagination: initialPagination, loading: false});
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<MachineServiceHistoryListRow> = [
    {
      title: t('machineServiceHistory.completionDate'),
      dataIndex: 'completionDate',
      render: (value: string) => <span>{formatDate(value)}</span>,
      // render: (value: string) => <span>{value}</span>,
    },
    {
      title: t('machineServiceHistory.name'),
      dataIndex: ['maintenance', 'name'],
    },
    {
      title: t('machineServiceHistory.description'),
      dataIndex: 'description',
    },
  ];

  return (
    <BaseTable
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            navigate('/machine-service-history/view/' + record.id);
          }, // click row
          // onDoubleClick: (event) => {}, // double click row
          // onContextMenu: (event) => {}, // right button click row
          // onMouseEnter: (event) => {}, // mouse enter row
          // onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
      columns={columns}
      dataSource={tableData.data}
      // pagination={tableData.pagination}
      loading={tableData.loading}
      // onChange={handleTableChange}
      scroll={{x: 800}}
      // bordered
    />
  );
};
