import React from 'react';
import * as S from './References.styles';
import {FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined} from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        &copy; 2024.{' '}
        <a href="#" target="_blank" rel="noreferrer">
          Apex Solutions.{' '}
        </a>
        {/* All rights reserved{' '} */}
        Powered by{' '}
        <a href="https://webnetglobal.com/" target="_blank" rel="noreferrer">
          WebNet Global.
        </a>
      </S.Text>
      {/* <S.Icons>
        <a href="https://github.com/altence/lightence-admin" target="_blank" rel="noreferrer">
          <GithubOutlined />
        </a>
        <a href="https://twitter.com/altence_team" target="_blank" rel="noreferrer">
          <TwitterOutlined />
        </a>
        <a href="https://www.facebook.com/groups/altence" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://linkedin.com/company/altence" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
      </S.Icons> */}
    </S.ReferencesWrapper>
  );
};
