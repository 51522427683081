import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {v4 as uuidv4} from 'uuid';
import {
  CreateMachineRequest,
  UpdateMachineRequest,
  createMachine,
  getAllInternalIds,
  getSingleMachine,
  updateMachine,
} from '@app/api/machine.api';
import {Button, Form, Select, message} from 'antd';
import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import {getAllManufacturers} from '@app/api/manufacturer.api';
import {MachineModel} from '@app/domain/MachineModel';
import moment from 'moment';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';
import dayjs from 'dayjs';
import {BaseUpload} from '@app/components/common/BaseUpload/BaseUpload';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {fileUpload} from '@app/api/file-upload.api';
import FormList from 'antd/lib/form/FormList';
import Operation from 'antd/lib/transfer/operation';
import {BaseCheckbox} from '@app/components/common/BaseCheckbox/BaseCheckbox';

interface FormMachineProps {
  edit?: boolean;
}

export const FormMachine: React.FC<FormMachineProps> = ({edit}) => {
  const {machineId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  let internalId = '';

  const [manufacturers, setManufacturers] = useState<ManufacturerModel[]>([]);
  const [internalIds, setInternalIds] = useState<string[]>([]);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [gettingEditData, setGettingEditData] = useState(false);
  const [isWarrantyActive, setIsWarrantyActive] = useState(false);

  const [currentEditMachineData, setCurrentEditMachineData] = useState<MachineModel>();
  const [initialValues, setInitialValues] = useState<any>();
  const [newGuid, setNewGuid] = useState<string>('');

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  const uploadProps = {
    name: 'file',
    // action: 'https://localhost:5001/Manufacturer/Upload',
    customRequest: (info: any) => {
      console.log(newGuid);
      const formData = new FormData();
      formData.append('file', info.file);
      formData.append('machineId', machineId != null ? machineId : newGuid);
      fileUpload(formData)
        .then((res: any) => {
          info.status = 'done';
          notificationController.success({message: t('machine.notifications.createSuccess')});
        })
        .catch((err: any) => {
          notificationController.error({message: t('machine.notifications.createError')});
        });
    },
    onChange: (info: any) => {
      const {status} = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        notificationController.success({message: t('uploads.successUpload', {name: info.file.name})});
      } else if (status === 'error') {
        notificationController.error({message: t('uploads.failedUpload', {name: info.file.name})});
      }
    },
    beforeUpload: (file: any) => {
      console.log(file);
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      if (edit) {
        if (machineId === undefined) {
          navigate('/machines/list');
          return;
        }
        setGettingEditData(true);

        await getSingleMachine(machineId).then((res: MachineModel) => {
          setCurrentEditMachineData(res);
          setGettingEditData(false);
          if (res.warrantyExpiryDate != null) setIsWarrantyActive(true);

          setInitialValues({
            internalId: res.internalId,
            name: res.name,
            model: res.model,
            serialNumber: res.serialNumber,
            description: res.description,
            manufactureDate: dayjs(res.manufactureDate),
            purchaseDate: dayjs(res.purchaseDate),
            warrantyExpiryDate: res.warrantyExpiryDate != null ? dayjs(res.warrantyExpiryDate) : null,
            manufacturerId: res.manufacturer.id,
            uploadFile: {},
          });
          internalId = res.internalId;
        });
      } else {
        setNewGuid(uuidv4());
      }

      getAllManufacturers().then((res) => {
        setManufacturers(res);
      });
      getAllInternalIds().then((res) => {
        if (edit) setInternalIds(res.filter((x) => x != internalId));
        else setInternalIds(res);
      });
    };

    fetchData();
  }, []);

  const onFinish = async (values: UpdateMachineRequest) => {
    setLoading(true);

    // We are saving only the year of the manufacture date
    values.manufactureDate = new Date(new Date(values.manufactureDate).getFullYear(), 0, 1, 12);

    if (edit && machineId) {
      values['id'] = machineId;

      updateMachine(values)
        .then((res) => {
          setLoading(false);
          notificationController.success({message: t('machine.notifications.updateSuccess')});
          navigate('/machines/');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notificationController.error({message: t('machine.notifications.updateError')});
        });

      setLoading(false);
      return;
    } else {
      values.id = newGuid;
      values.manufactureDate = new Date(values.manufactureDate.toISOString());
      values.purchaseDate = new Date(values.purchaseDate.toISOString());
      if (isWarrantyActive) values.warrantyExpiryDate = new Date(values.warrantyExpiryDate.toISOString());

      createMachine(values as CreateMachineRequest)
        .then((res) => {
          console.log(res);
          setLoading(false);
          notificationController.success({message: t('machine.notifications.createSuccess')});
          navigate('/machines/');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notificationController.error({message: t('machine.notifications.createError')});
        });
    }
  };

  return (
    <>
      {gettingEditData ? (
        ''
      ) : (
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          initialValues={initialValues}
          name="validateForm"
          footer={
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </BaseButton>
            </BaseButtonsForm.Item>
          }
          onFinish={onFinish}
        >
          <BaseRow gutter={{xs: 10, md: 15, xl: 30}} style={{maxWidth: '1000px'}}>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="internalId"
                label={t('machine.form.internalId')}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(new Error(t('machine.form.validationLabels.internalId')));
                      }
                      if (internalIds.includes(value)) {
                        return Promise.reject(new Error(t('machine.form.validationLabels.duplicateInternalId')));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="name"
                label={t('machine.form.machineName')}
                rules={[{required: true, message: t('machine.form.validationLabels.machineName')}]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="model"
                label={t('machine.form.model')}
                rules={[{required: true, message: t('machine.form.validationLabels.model')}]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="serialNumber"
                label={t('machine.form.serialNumber')}
                rules={[{required: true, message: t('machine.form.validationLabels.serialNumber')}]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="manufacturerId"
                label={t('machine.form.manufacturer')}
                rules={[{required: true, message: t('machine.form.validationLabels.manufacturer')}]}
              >
                <Select
                  showSearch
                  style={{width: 200}}
                  placeholder={t('machine.form.searchToSelect')}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={manufacturers.map((model) => ({
                    value: model.id,
                    label: model.name,
                  }))}
                />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="manufactureDate"
                label={t('machine.form.manufactureDate')}
                rules={[{required: true, message: t('machine.form.validationLabels.manufactureDate')}]}
              >
                <BaseDatePicker format={'YYYY'} picker="year" />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item name="isWarrantyActive" label={t('machine.form.isWarrantyActive')}>
                <BaseCheckbox checked={isWarrantyActive} onChange={(e) => setIsWarrantyActive(e.target.checked)} />
              </BaseButtonsForm.Item>
            </BaseCol>

            {isWarrantyActive && (
              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item name="warrantyExpiryDate" label={t('machine.form.warrantyExpiryDate')}>
                  <BaseDatePicker format={'DD.MM.YYYY'} />
                </BaseButtonsForm.Item>
              </BaseCol>
            )}

            <BaseCol xs={24} md={24}>
              <BaseButtonsForm.Item
                name="purchaseDate"
                label={t('machine.form.purchaseDate')}
                rules={[{required: true, message: t('machine.form.validationLabels.purchaseDate')}]}
              >
                <BaseDatePicker format={'DD.MM.YYYY'} />
              </BaseButtonsForm.Item>
            </BaseCol>
            {/* <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="uploadFile"
                label={t('forms.validationFormLabels.upload')}
              >
                <BaseUpload {...uploadProps}>
                  <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
                </BaseUpload>
              </BaseButtonsForm.Item>
            </BaseCol> */}

            <BaseCol xs={24} md={18}>
              <BaseButtonsForm.Item
                name="description"
                label={t('machine.form.description')}
                rules={[{required: true, message: t('machine.form.validationLabels.description')}]}
              >
                <BaseInput.TextArea rows={4} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      )}
    </>
  );
};
