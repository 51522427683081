export enum eMaintenanceType {
  Not_Set = 0,
  Regular_Maintenance = 1,
  Downtime = 2,
  Planned_Maintenance = 3,
}

export enum eMaintenancePriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum eUserRoles {
  Administrator = 'Administrator',
  Manager = 'Manager',
  Supervisor = 'Supervisor',
  Technician = 'Technician',
}

export enum eSchedules {
  Not_Set = 0,
  One_Week = 7,
  Two_Week = 14,
  Three_Week = 21,
  One_Month = 30,
  Three_Month = 90,
  Six_Month = 180,
  Year = 365,
}
