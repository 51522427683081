import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {FormRegularMaintenance} from '@app/components/maintenance/regular-maintenance/FormRegularMaintenance/FormRegularMaintenance';

const AddRegularMaintenancePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('maintenance.regular.addPageHeader')}</PageTitle>
      <PageLayout title={t('maintenance.regular.addPageHeader')} backButtonLink={'/machines/list'}>
        <FormRegularMaintenance />
      </PageLayout>
    </>
  );
};

export default AddRegularMaintenancePage;
