import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMounted} from '@app/hooks/useMounted';
import {useNavigate, useParams} from 'react-router-dom';
import {useResponsive} from '@app/hooks/useResponsive';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {ManufacturerModel} from '@app/domain/ManufacturerModel';
import * as S from './ViewManufacturer.styles';
import {getSingleManufacturer} from '@app/api/manufacturer.api';

interface ViewManufacturerProps {
  setManufacturerTitle: Dispatch<SetStateAction<string>>;
}

export const ViewManufacturer: React.FC<ViewManufacturerProps> = ({setManufacturerTitle}) => {
  const {manufacturerId} = useParams();
  const {isMounted} = useMounted();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isTablet} = useResponsive();

  const [manufacturer, setManufacturer] = useState<ManufacturerModel>();

  const fetchManufacturer = useCallback(
    async (manufacturerId: string) => {
      try {
        const data = await getSingleManufacturer(manufacturerId);
        if (isMounted.current) {
          setManufacturer(data);
          setManufacturerTitle(data!.name);
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    },
    [manufacturerId, isMounted],
  );

  useEffect(() => {
    fetchManufacturer(manufacturerId!);
  }, [fetchManufacturer]);

  return (
    <>
      {manufacturer && (
        <>
          <BaseRow style={{justifyContent: 'space-between'}}>
            <S.LeftSideCol xl={24} xxl={24} id="desktop-content">
              <S.Section>
                <S.SectionHeader>
                  <S.H2>{t('manufacturer.manufacturerInfo')}</S.H2> <S.Hr />
                </S.SectionHeader>
                <S.SectionContent>
                  <S.SectionElement>
                    <S.SectionElementLabel>{t('manufacturer.name')}:</S.SectionElementLabel>
                    <S.SectionElementValue>{manufacturer.name}</S.SectionElementValue>
                  </S.SectionElement>
                  <S.SectionElement>
                    <S.SectionElementLabel>{t('manufacturer.industry')}:</S.SectionElementLabel>
                    <S.SectionElementValue>{manufacturer.industry}</S.SectionElementValue>
                  </S.SectionElement>
                  <S.SectionElement>
                    <S.SectionElementLabel>{t('manufacturer.website')}:</S.SectionElementLabel>
                    <S.SectionElementValue>{manufacturer.website}</S.SectionElementValue>
                  </S.SectionElement>
                  {/* <S.SectionElement>
                    <S.SectionElementLabel>Country:</S.SectionElementLabel>
                    <S.SectionElementValue>{manufacturer.country}</S.SectionElementValue>
                  </S.SectionElement> */}
                </S.SectionContent>
                <S.SectionElement style={{marginTop: '30px'}}>
                  <S.SectionElementLabel>{t('manufacturer.description')}:</S.SectionElementLabel>
                  <S.SectionElementValue>{manufacturer.description}</S.SectionElementValue>
                </S.SectionElement>
                <S.SectionElement style={{marginTop: '30px'}}>
                  <S.SectionElementLabel>{t('manufacturer.authorizedServiceProvider')}:</S.SectionElementLabel>
                  <S.SectionElementValue>{manufacturer.authorizedServiceProvider}</S.SectionElementValue>
                </S.SectionElement>
              </S.Section>
            </S.LeftSideCol>
          </BaseRow>
        </>
      )}
    </>
  );
};
