import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './ViewMachine.styles';
import {useMounted} from '@app/hooks/useMounted';
import {useNavigate, useParams} from 'react-router-dom';
import {getSingleMachine} from '@app/api/machine.api';
import {MachineModel} from '@app/domain/MachineModel';
import {formatDate, formatYear} from '@app/utils/utils';
import {Tabs, TabsProps} from 'antd';
import {MachineList} from '../ListMachine';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {PlusOutlined} from '@ant-design/icons';
import {useResponsive} from '@app/hooks/useResponsive';
import {ListRegularMaintenance} from '@app/components/maintenance/regular-maintenance/ListRegularMaintenance';
import {MachineServiceHistoryList} from '@app/components/machine-service-history/ListMachineServiceHistory';
import {ListRegularMaintenanceForMachine} from '@app/components/maintenance/regular-maintenance/ListRegularMaintenanceForMachine';
import {ListDowntimeMaintenanceForMachine} from '@app/components/maintenance/downtime-maintenance/ListDowntimeMaintenanceForMachine';
import {ListPlannedMaintenanceForMachine} from '@app/components/maintenance/planned-maintenance/ListPlannedMaintenanceForMachine';

interface ViewMachineProps {
  setMachineName: Dispatch<SetStateAction<string>>;
}

export const ViewMachine: React.FC<ViewMachineProps> = ({setMachineName}) => {
  const {machineId} = useParams();
  const {isMounted} = useMounted();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isTablet} = useResponsive();

  const [machine, setMachine] = useState<MachineModel>(); // State to store the machine data

  const fetchMachine = useCallback(
    async (machineId: string) => {
      try {
        const data = await getSingleMachine(machineId);
        if (isMounted.current) {
          setMachine(data);
          setMachineName(data.name);
          // setMachineName(data.name);
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    },
    [machineId, isMounted],
  );

  useEffect(() => {
    fetchMachine(machineId!);
  }, [fetchMachine]);

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('objects.serviceHistory'),
      children: <MachineServiceHistoryList machineId={machineId!} />,
    },
    {
      key: '2',
      label: t('objects.maintenances'),
      children: <ListRegularMaintenanceForMachine machineId={machineId!} />,
    },
    {
      key: '3',
      label: t('objects.downtime'),
      children: <ListDowntimeMaintenanceForMachine machineId={machineId!} />,
    },
    {
      key: '4',
      label: t('objects.plannedServices'),
      children: <ListPlannedMaintenanceForMachine machineId={machineId!} />,
    },
  ];

  const desktopAndTabletLayout = (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 2fr 2fr',
          alignItems: 'start',
          columnGap: '20px',
          marginBottom: '20px',
        }}
      >
        <img
          src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
          style={{maxWidth: '250px'}}
        />

        <S.Table>
          <S.TableRow>
            <S.TableData>{t('machine.internalId')}</S.TableData>
            <S.TableData>
              <b>{machine?.internalId}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.name')}</S.TableData>
            <S.TableData>
              <b>{machine?.name}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.description')}</S.TableData>
            <S.TableData>
              <b>{machine?.description}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.manufacturer')}</S.TableData>
            <S.TableData>
              <b>{machine?.manufacturer.name}</b>
            </S.TableData>
          </S.TableRow>
        </S.Table>

        <S.Table>
          <S.TableRow>
            <S.TableData>{t('machine.manufactureDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.manufactureDate ? formatYear(machine.manufactureDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.purchaseDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.purchaseDate ? formatDate(machine.purchaseDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.warrantyExpiryDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.warrantyExpiryDate ? formatDate(machine.warrantyExpiryDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
        </S.Table>
      </div>

      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
        <BaseButton
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate('/regular-maintenance/add?machineid=' + machineId);
          }}
        >
          {t('machine.actions.addMaintenance')}
        </BaseButton>
        <BaseButton
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate('/downtime-maintenance/add?machineid=' + machineId);
          }}
        >
          {t('machine.actions.addDowntime')}
        </BaseButton>
        <BaseButton
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate('/planned-maintenance/add?machineid=' + machineId);
          }}
        >
          {t('machine.actions.addPlannedService')}
        </BaseButton>
      </div>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </>
  );

  const mobileLayout = (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'start',
          columnGap: '20px',
          marginBottom: '20px',
          rowGap: '20px',
        }}
      >
        <img
          src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
          style={{maxWidth: '250px'}}
        />

        <S.Table>
          <S.TableRow>
            <S.TableData>{t('machine.name')}</S.TableData>
            <S.TableData>
              <b>{machine?.name}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.description')}</S.TableData>
            <S.TableData>
              <b>{machine?.description}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.manufacturer')}</S.TableData>
            <S.TableData>
              <b>{machine?.manufacturer.name}</b>
            </S.TableData>
          </S.TableRow>
        </S.Table>

        <S.Table>
          <S.TableRow>
            <S.TableData>{t('machine.manufactureDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.manufactureDate ? formatDate(machine.manufactureDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.purchaseDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.purchaseDate ? formatDate(machine.purchaseDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
          <S.TableRow>
            <S.TableData>{t('machine.warrantyExpiryDate')}</S.TableData>
            <S.TableData>
              <b>{machine?.warrantyExpiryDate ? formatDate(machine.warrantyExpiryDate) : ''}</b>
            </S.TableData>
          </S.TableRow>
        </S.Table>
      </div>

      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px', flexWrap: 'wrap'}}>
        <BaseButton type="default" icon={<PlusOutlined />}>
          {t('machine.actions.addMaintenance')}
        </BaseButton>
        <BaseButton type="default" icon={<PlusOutlined />}>
          {t('machine.actions.addDowntime')}
        </BaseButton>
        <BaseButton type="default" icon={<PlusOutlined />}>
          {t('machine.actions.addPlannedService')}
        </BaseButton>
      </div>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </>
  );

  return <>{isTablet ? desktopAndTabletLayout : mobileLayout}</>;
};
