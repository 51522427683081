import styled from 'styled-components';
import {media} from '@app/styles/themes/constants';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';

export const Section = styled.div`
  margin: 10px 0 35px 0;

  // @media only screen and ${media.sm} {
  //   align-items: center;
  // }

  // @media only screen and ${media.xl} {
  //   flex-direction: row;
  //   justify-content: space-between;
  // }
`;

export const SectionHeader = styled.div``;

export const H2 = styled.h2`
  margin: 0;
`;

export const SectionContent = styled.div`
  display: grid;

  @media only screen and ${media.xs} {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  @media only screen and ${media.md} {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media only screen and ${media.xl} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }
`;

export const SectionElement = styled.div`
  display: grid;
`;

export const SectionElementLabel = styled.label`
  font-size: 14px;
  color: #bfc0c2;
`;

export const SectionElementValue = styled.span`
  font-weight: 700;
`;

export const SectionElementValueLink = styled.a`
  font-weight: 700;
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 0 20px 0;
  border-left: 0;
  opacity: 0.6;
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  @media only screen and ${media.xl} {
    margin-bottom: 0;
  }
`;

export const Icons = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: nowrap;

  svg {
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const RightSideCol = styled(BaseCol)`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  // background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    overflow: auto;
  }
`;
