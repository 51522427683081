import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {FormMachine} from '@app/components/machine/FormMachine/FormMachine';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {useParams} from 'react-router-dom';

const EditMachinePage: React.FC = () => {
  const {t} = useTranslation();
  const {machineId} = useParams();

  return (
    <>
      <PageTitle>Izmena masine</PageTitle>
      <PageLayout title={t('machine.editPageTitle')} backButtonLink={'/machines/view/' + machineId}>
        <FormMachine edit={true} />
      </PageLayout>
    </>
  );
};

export default EditMachinePage;
